// third party imports
import React from "react";
import PropTypes from "prop-types";
import { Card, Tooltip } from "antd";

// local repo imports
import Tag from "../Tag";
import EntvinLogo from "../../assets/entvin_logo.png";
import MolecularWeight from "../../assets/molecular_weight.png"
import CasName from "../../assets/cas_name.png"
import Molecule from "../../assets/molecule.png"


import "./ChemicalInfo.css";

const ChemicalInfo = (props) => {
  // const { hasCASNumber, chemicalMeta, tags, hiddenTags } = props;
  const {chemicalName, casData} = props

  let chemicalMeta=[
    {
      image: Molecule,
      label: "Molecular formula",
      value: <p dangerouslySetInnerHTML={{ __html: casData?casData.molecularFormula:"" }} ></p>,
      link: "/",
      linkLabel: "View Full Structure",
      backgroundColor:"#E7FAFF"
    },
    {
      image: MolecularWeight,
      label: "Molecular Weight",
      value: casData?casData.molecularMass:"",
      backgroundColor:"#EAECFD"

    },
    {
      image: CasName,
      label: "CAS Name",
      value:  <p dangerouslySetInnerHTML={{ __html: casData? casData.name:"" }} ></p>,
      link: "/",
      linkLabel: "View Full IUPAC",
      backgroundColor:"#F5EEFD"

    },
  ];
  
  return (
    casData?(
       <div className="chemicalInfoSection">
        <Card className="molecularStructure">
          <img  style={{ maxWidth: "100%", maxHeight: "100%" }} src={`data:image/svg+xml;utf8,${encodeURIComponent(casData.image)}`} alt="Molecular Structure" />
        </Card>
        <div className="chemicalInfo">
          <div className="chemicalLabel">
            <div className="chemicalName">
              <Tooltip placement="topLeft" title={chemicalName}>
                {chemicalName}
              </Tooltip>
            </div>
            <div className="chemicalSubName">/ CAS: {casData.rn}</div>
          </div>
          <div className="tags">
            <div style={{height:'32px', flex: "0 0 133px", fontSize:"16px", paddingTop:"2px", marginRight:"10px"}}>
                Alternative names:
            </div>
            
            {casData.synonyms.map((tag, index) => {
              const label = index <= 3 ? tag : `+${casData.synonyms.length-4}`;
              if(index<4){
                return <Tag label={label} tooltip={tag} />;
              }else if(index==5){
                let combinedTag=casData.synonyms.slice(4).join(',\n');
                return <Tag label={label} tooltip={combinedTag} />;
              }
            })}
          </div>
          {/* Chemical Info cards */}
          <div className="chemicalInfoCards">
            {chemicalMeta.map((info) => {
              const { image, label, value, link, linkLabel, backgroundColor } = info;
              return (
                <Card className="infoCard">
                  <div style={{backgroundColor:backgroundColor, width:"48px", height:'48px', borderRadius:'10px', opacity:0.7, alignItems:'center', justifyContent:'center', display:'flex'}} ><img  src={image} alt={label} /> </div>
                  <div className="infoCardLabel">{label}</div>
                  <div className="infoCardValue">{value}</div>
                  {/* {link && (
                    <a href={link} target="_blank" className="infoCardLink">
                      {linkLabel}
                    </a>
                  )} */}
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    ):(
      <div className="chemicalLabel1">
          <div className="chemicalName">{chemicalName}</div>
          {/* {hasCASNumber && (
            <div className="chemicalSubName">/ CAS: 100-44-7</div>
          )} */}
      </div>
    )
   
    
  );
};

export default ChemicalInfo;

ChemicalInfo.propTypes = {
  chemicalMeta: PropTypes.array,
  hasCASNumber: PropTypes.bool,
  tags: PropTypes.array,
  hiddenTags: PropTypes.number,
};

ChemicalInfo.defaultProps = {
  chemicalMeta: [],
  hasCASNumber: false,
  tags: [],
  hiddenTags: 1,
};
