// third party imports
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from 'antd';

// local repo imports
import "./Tag.css";

const Tag = (props) => {
  const { label, bgColor, labelColor, tooltip } = props;
  const modifiedTooltip = tooltip.length ? tooltip : label;
  return (
    <span
      style={{ backgroundColor: bgColor, color: labelColor }}
      // title={modifiedTooltip}
      className="tag"
    >
      <Tooltip placement="topLeft" title={<p dangerouslySetInnerHTML={{ __html: modifiedTooltip }}></p>}>
        <p dangerouslySetInnerHTML={{ __html: label }}></p>
      </Tooltip>
    </span>
  );
};

export default Tag;

Tag.propTypes = {
  label: PropTypes.string,
  labelColor: PropTypes.string,
  bgColor: PropTypes.string,
  tooltip: PropTypes.string,
};

Tag.defaultProps = {
  label: "",
  labelColor: "#344054",
  bgColor: "#E4E9F1",
  tooltip: "",
};
