import React, { useEffect, useState } from 'react';
import ReactFlow from 'reactflow';
import { Handle, Position } from 'reactflow';
import { Tooltip, Carousel } from 'antd';
import Molecule from "../../assets/molecule.png"
import customFetch from '../../utils/customFetchFunc';

import 'reactflow/dist/style.css';
import './ROS.css'

const MyNodeComponent = ({ data}) => {
    const label = data.label;
  
    return (
        
      <div className="node">
        <Handle type="target" position={Position.Left} />

        <div className="node-label" style={{ whiteSpace: 'pre-line', backgroundColor: data.bgColor, color: data.labelColor }}>
             <span
                className="tag1"
            >
                
                <Tooltip placement="topLeft" title={<p dangerouslySetInnerHTML={{ __html: label }}></p>}>
                    {<p dangerouslySetInnerHTML={{ __html: label }}></p>}
                </Tooltip>
            </span>
            
        </div>
        <Handle type="source" position={Position.Right} />

      </div>
    );
  };
  
// const initialNodes = [
//     { id: '1', position: { x: 0, y: 50 }, type: 'my-node', data: { label: 'ABCD ABCD ABCD ABCD ABCD ABCD ABCDABCD ABCD ABCD ABCD ABCD ABCD ABCD ' } },
//     { id: '2', position: { x: 500, y: 50 }, type: 'my-node', data: { label: '2' } },
//     { id: '3', position: { x: 500, y: 100 }, type: 'my-node', data: { label: '3' } },

// ];
// const initialEdges = [{ id: 'e1-2', source: '1', target: '2',  markerEnd: { type: 'arrow', width: 30, height: 30 } },{ id: 'e1-2', source: '1', target: '3',  markerEnd: { type: 'arrow', width: 30, height: 30 } }];


const nodeTypes = {
    'my-node': MyNodeComponent,
};

function createNodesAndEdges(element, mapping, X, Y, id) {
    let nodes = []
    let edges = []
    mapping.forEach((i) => {
        if (i.raw_material.toLowerCase() == element.toLowerCase()) {
            let index = nodes.length
            const nodeId = `${id}-${index}`;
            nodes.push({
                id: nodeId,
                position: { x: X + 500, y: Y + (parseInt((index+1)/2))*((index % 2) ? (-120) : (120))},
                type: 'my-node',
                data: { label: i.end_use_case,
                bgColor: '#FF81CF',
                labelColor: 'white'}
            });
            
            edges.push({
                id: `${id}-e-${index}`,
                source: id,
                target: nodeId,
                style:{stroke:'#FF81CF'},
                markerEnd: { type: 'arrowclosed', width: 30, height: 30, color:'#FF81CF' }
            });
            
            // Recursive call
            const { nodes: nestedNodes, edges: nestedEdges } = createNodesAndEdges(i.end_use_case, mapping, X + 500, Y +(parseInt((index+1)/2))*((index % 2) ? (-120) : (120)), nodeId);
            
            nodes = nodes.concat(nestedNodes);
            edges = edges.concat(nestedEdges);
        }
    });

    return { nodes, edges };
}
function createNodesAndEdgesBack(element, mapping, X, Y, id) {
    let nodes = []
    let edges = []
    mapping.forEach((i) => {
        if (i.end_use_case.toLowerCase() == element.toLowerCase()) {
            let index = nodes.length
            const nodeId = `${id}_${index}`;
            nodes.push({
                id: nodeId,
                position: { x: X - 500, y: Y + (parseInt((index+1)/2))*((index % 2) ? (-120) : (120)) },
                type: 'my-node',
                data: { label: i.raw_material ,
                bgColor: '#7F56D9',
                labelColor: 'white'}
            });
            
            edges.push({
                id: `${id}_e_${index}`,
                source: nodeId,
                target: id,
                style:{stroke:'#7F56D9'},
                markerEnd: { type: 'arrowclosed', width: 30, height: 30, color:'#7F56D9' }
            });
            // Recursive call
            const { nodes: nestedNodes, edges: nestedEdges } = createNodesAndEdgesBack(i.raw_material, mapping, X - 500, Y + (parseInt((index+1)/2))*((index % 2) ? (-120) : (120)), nodeId);
            if(nestedNodes && nestedEdges){
                nodes = nodes.concat(nestedNodes);
                edges = edges.concat(nestedEdges);
            }
            console.log(nodes, edges)
            
        }
    });

    return { nodes, edges };
}


export default function ROS(props) {
    const {chemicalName} = props
    const [graphData, setgraphData] = useState({initialNodes:[], initialEdges:[]});
    const [ manufacturingProcess, setManufacturingProcess] = useState(null);
    const [manufacturingImages, setManufacturingImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % manufacturingImages.length);
    };

    const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? manufacturingImages.length - 1 : prevIndex - 1
    );
    };
    

    // console.log(chemicalName)
    useEffect(() => {
        if(chemicalName.length==0){
            return
        }
        customFetch(`https://database.entvin.com/getROS?chemicalName=${encodeURIComponent(chemicalName)}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((results) => {
        // setChemicalInfo(data)
        let data = results['ROS']
        setManufacturingProcess(results['manufacturingProcess'])
        setManufacturingImages(results['manufacturingImages'])
        console.log(data)

        let initial_node =  { id: '1', position: { x: 400, y: 300 }, type: 'my-node', data: { label: data.best_match , bgColor: '#0E1728',labelColor: 'white'} }
        let {nodes, edges} = createNodesAndEdges(data.best_match, data.mapping, 400, 300, '1')
        let {nodes:nodes1, edges:edges1} = createNodesAndEdgesBack(data.best_match, data.mapping, 400, 300, '1')
        console.log(nodes1, edges1)

        nodes.push(initial_node)
        nodes = nodes.concat(nodes1)
        edges = edges.concat(edges1)
        setgraphData(
            {
                initialNodes: nodes,
                initialEdges: edges
            }
        )
        // Assuming data is an array of autocomplete options
        // setOptions(data.map(i=> {return {"value": i}}));
        })
        .catch((error) => {
            console.error('Error fetching autocomplete options:', error);
            if ( error.response.status === 401) {
                window.location.reload();
            }
        });
    }, []);

    return (
        <>
            {
                graphData.initialEdges.length?(
                    <div className='ROSgraph'>
                        <div className='rosHeadline'>
                            Routes of Synthesis 
                        </div>
                        <div className='rosBorderBox'>

                        </div>
                        <div className='rosContent'>
                            <div className='reactFlowGraph'>
                                <ReactFlow className="reactFlowGraph" style={{border:'None'}} nodes={graphData.initialNodes} edges={graphData.initialEdges} nodeTypes={nodeTypes} />
                            
                            </div>
                            
                            {manufacturingProcess?(
                                <div className='ManufacturingProcess'>
                                    <div className='ManufacturingImage'>
                                        <div style={{backgroundColor:"#E7FAFF", width:"48px", height:'48px', borderRadius:'10px', opacity:0.7, alignItems:'center', justifyContent:'center', display:'flex'}} ><img  src={Molecule} alt='Manufacturing' /> </div>
                                    </div>
                                    <div className='ManufacturingContent'>
                                        <div className='ManufacturingTitle'>
                                            Manufacturing Process
                                        </div>

                                        <div className='ManufacturingText'>
                                            {manufacturingProcess}
                                        </div>
                                    </div>
                                </div>
                            ):(<></>)}
                        </div>

                    </div>
                ):
                ( manufacturingImages.length?(
                        <div className='ROSgraph'>
                            <div className='rosHeadline'>
                                Manufacturing Process
                            </div>
                            <div className='rosBorderBox'>

                            </div>
                            <div className='rosContent'>
                                <div className='manufacturingImages'>

                                    {
                                        manufacturingImages.map((image,index)=>{
                                            return (
                                                    <img
                                                        key={index}
                                                        src={image}
                                                        alt={`Manufacturing Image ${index}`}
                                                        className="slider-image"
                                                    />
                                            )
                                        })
                                    }  

                                </div>
                            </div>
                               

                        </div>
                    ):(
                        <></>
                    )
                    
                )
            }
        </>
    );
}