export const BUYERS_BUTTON_STYLING = {
  color: "white",
  backgroundColor: "#7F56D9",
};

export const SELLERS_BUTTON_STYLING = {
  color: "white",
  backgroundColor: "#C11574",
};

export const SELECT_OPTIONS = [
  {
    label: "Test 1",
    value: "test1",
  },
  {
    label: "Test 2",
    value: "test2",
  },
  {
    label: "Test 3",
    value: "test3",
  },
  {
    label: "Test 4",
    value: "test4",
  },
  {
    label: "Test 5",
    value: "test5",
  },
  {
    label: "Test 6",
    value: "test6",
  },
];
