// third party library imports
import React from "react";
import { Link } from "react-router-dom";

// local repo imports
import entvinLogo from "../../assets/entvin_logo.png";
import { isUserLoggedin } from "../../utils/helpers";
import { NAVIGATION_LINKS } from "./Header.constants";
import "./Header.css";

const Header = (props) => {
  const {currentSelection} = props

  console.log(currentSelection, 'cureent')
  return (
    <div className="header">
      <img src={entvinLogo} alt="entvin logo" />
      <div className="nav-links">
        <Link className={currentSelection=='chemical'?'selectedlink':'link'} to='/'>
          Chemical Discovery
        </Link>
        <Link className={currentSelection=='company'?'selectedlink':'link'} to='/company'>
          Company Discovery
        </Link>
        <Link className={currentSelection=='pricing'?'selectedlink':'link'} to='/pricing'>
          Pricing
        </Link>
      </div>
    </div>
  );
};

export default Header;
