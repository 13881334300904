// third party imports
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Tabs, Card, Spin } from "antd";
// local repo imports
import Header from "../../components/Header";
import Tag from "../../components/Tag";
import DashboardFilters from "../../components/DashboardFilters";
import EntityLocations from "../../components/EntityLocations";
import EntityList from "../../components/EntityList"
import EntityListAll from "../../components/EntityListAll/EntityListAll";
import ChemicalInfo from "../../components/ChemicalInfo";
import ROS from "../../components/ROSGraph"
import PatentsList from "../../components/PatentsList"
import customFetch from '../../utils/customFetchFunc';

import {
  getSearchResult,
  getModifiedChemicalChildren,
} from "./Dashboard.helpers";
import {
  DASHBOARD_TABS,
  CHEMICAL_INFO,
  CHEMICAL_CHILDREN,
  DASHBOARD_FILTERS,
} from "./Dashboard.constants";
import "./Dashboard.css";
import {useChemicalStore} from '../../zustand';
import { useNavigate } from 'react-router-dom';


const Dashboard = () => {

  const [options, setOptions] = useState([]);
  const [dashboardFilters, setDashboardFilters] = useState(DASHBOARD_FILTERS);
  
  const [filteredChemicalData, setFilteredChemicalData] = useState(null)
  const [loading, setLoading] = useState(false);
  const chemicalSearched = useChemicalStore((state)=>state.chemicalSearched)
  const chemicalInfo = useChemicalStore((state)=>state.chemicalInfo)
  const setChemicalSearched = useChemicalStore((state)=>state.setChemicalSearched)
  const setChemicalInfo = useChemicalStore((state)=>state.setChemicalInfo)
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  console.log(typeof queryParams.get('pdf'),'pdf')

  useEffect(() => {
    console.log(chemicalInfo.data, 'asssssss')
    if(chemicalInfo.data.length==0){
      navigate('/')

    }
    // This code will run whenever rerender changes
    // You can put any logic here that you want to run after a re-render
    console.log('filterschanges', dashboardFilters)
    let tempChemicalData=[]
    if(chemicalInfo.data.length>0){
      chemicalInfo.data.forEach(entity=>{
        let entitytobeadded = true
        if(!dashboardFilters.buyers && entity.entity_type=='buyer'){
          entitytobeadded=false
        }
        if(!dashboardFilters.sellers && entity.entity_type=='seller'){
          entitytobeadded=false
        }
        if(dashboardFilters.selectedCountries.length>0 && !dashboardFilters.selectedCountries.includes(entity.country)){
          entitytobeadded=false
        }
        if(dashboardFilters.selectedRegistrations.length>0 && !dashboardFilters.selectedRegistrations.includes(entity.registrations)){
          entitytobeadded=false
        }
        if(entitytobeadded){
          tempChemicalData.push(entity)
        }
      })
      setFilteredChemicalData(tempChemicalData)
    }
  }, [dashboardFilters]);


  const handleSearch = (value) => {
    value=value.trim()
    setOptions([]);
    if (value&& value.length>2) {
      if(options[0] && options[0]["value"] && options[0]["value"][0] && !options[0]["value"][0].includes('No results')){
        if(options[0] && options[0]["value"] && options[0]["value"][0] && options[0]["value"][0].toLowerCase().replace(/\s/g, '').includes(value.toLowerCase().replace(/\s/g, ''))){
          console.log(options[0]["value"])
          return
        }
      }
      
      // Make an API call to fetch autocomplete options based on the value
      customFetch(`https://database.entvin.com/getAutoCompleteOptions?chemicalInput=${encodeURIComponent(value)}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log(data)
          if(data.length){
            setOptions(data.map(i=> {return {"value": [i.chemical_name, i.cas_number],
              "label":(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                <span style={{textOverflow: "ellipsis",whiteSpace: "normal", overflow: "hidden"}}>
                  {i.chemical_name}
                </span>
                <span style={{ width: '10px' }}></span> {/* Adjust the width as per your requirement */}
                <span>{i.cas_number?i.cas_number:""}</span>
              </div>
            )
            }}));
          }else{
            setOptions([{"value": [`No results for ${value}`],
              "label":(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                <span>
                  {`No results for ${value}`}
                </span>
              </div>
              ),
              "disabled":true
            }]);
          }
          // Assuming data is an array of autocomplete options
          
        })
        .catch((error) => {
          console.error('Error fetching autocomplete options:', error);
          if ( error.response.status === 401) {
            window.location.reload();
          }
        });
    } else {
      setOptions([]);
    }
  };

  const onSelect = (value) => {
    console.log("onSelect11111", value);
    if(value[0].length==0){
      return
    }
    setChemicalInfo({data:[], casData:null})
    setLoading(true); // Set loading to true
    setChemicalSearched(value[0])
    customFetch(`https://database.entvin.com/getChemicalData?chemicalName=${encodeURIComponent(value[0])}&casNo=${encodeURIComponent(value[1])}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      setFilteredChemicalData(null)
      console.log(data)
      setChemicalInfo(data)
      setDashboardFilters(DASHBOARD_FILTERS)
      setLoading(false); // Set loading back to false when API call is complete

      // Assuming data is an array of autocomplete options
      // setOptions(data.map(i=> {return {"value": i}}));
    })
    .catch((error) => {
      console.error('Error fetching autocomplete options:', error);
      setLoading(false); // Set loading back to false when API call is complete
      if ( error.response.status === 401) {
        window.location.reload();
      }

    });
  };

  const onTabChange = (key) => {
    console.log(key);
  };

  const hasCASNumber = true;
  const { showChildren, hiddenChildren } =
    getModifiedChemicalChildren(CHEMICAL_CHILDREN);
  return (
    <>
      <Header 
        currentSelection ='chemical'
      />
      <div className="dashboard">
        {/* Search section */}
        <div className="searchbar">
          <div className="label">Find and Explore Chemicals</div>
          <div className="searchBox">
            <AutoComplete
              className="autoComplete"
              options={options}
              onSelect={onSelect}
              onSearch={handleSearch}
            >
              <Input
                className="inputBox"
                placeholder="Search Chemical Name or CAS number"
                prefix={<SearchOutlined />}
              />
            </AutoComplete>
          </div>
        </div>

        {/* Chemical Info section */}
        {loading ? ( // Render the Spin component while loading
          <Spin  className="custom-spinner" />
        ) : (
          <>
            <ChemicalInfo
              // hasCASNumber
              // tags={showChildren}
              // hiddenTags={hiddenChildren}
              // chemicalMeta={CHEMICAL_INFO}
              chemicalName={chemicalSearched}
              casData={chemicalInfo.casData}
            />

          {/* Entity section */}
          {/* <div className="navigation">
            <Tabs
              defaultActiveKey="1"
              items={DASHBOARD_TABS}
              onChange={onTabChange}
            />
          </div> */}

          <div>
            <div className="entityLocations">
              <div className="sectionNameWrapper"> Entity Locations & List</div>
              <DashboardFilters
                dashboardFilters={dashboardFilters}
                setDashboardFilters={setDashboardFilters}
                countries = {[...new Set(chemicalInfo.data ? chemicalInfo.data.map(entity => entity.country) : [])].map(value => ({ label: value, value: value })).sort((a, b) => a.label.localeCompare(b.label))}              
                registrations={[...new Set(chemicalInfo.data?chemicalInfo.data.map(entity=>{return entity.registrations}):[])].map(value=>{return{label:value, value:value}})}
              />
            </div>
            <EntityLocations 
              chemicalInfo = {filteredChemicalData?{data:filteredChemicalData}:chemicalInfo}
            />
            {

              queryParams.get('pdf')?
              (
                <EntityListAll
                  chemicalInfo = {filteredChemicalData?{data:filteredChemicalData, casData: chemicalInfo.casData}:chemicalInfo}
                />
              ):(
                <EntityList
                  chemicalInfo = {filteredChemicalData?{data:filteredChemicalData, casData: chemicalInfo.casData}:chemicalInfo}
                />
              )
            }
            
            <ROS 
              chemicalName = { chemicalSearched }
            />
            <PatentsList
              chemicalName = { chemicalSearched }
            />
          </div>
        </>)}
      </div>
    </>
  );
};

export default Dashboard;
