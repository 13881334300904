// third party imports
import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Tabs, Card, Spin } from "antd";
import CompanyInfo from "../../components/CompanyInfo";
// local repo imports
import Header from "../../components/Header";
import FoundedYear from "../../assets/founderYear.png";
import Category from "../../assets/category.png";
import Employees from "../../assets/employees.png";
import CompanyContacts from "../../components/CompanyContacts"
import CompanyProducts from "../../components/CompanyProducts"
import customFetch from '../../utils/customFetchFunc';

import "./Company.css";


const CompanyPage = () => {
    
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [companyName, setCompanyName] = useState(null)
 

    const handleSearch = (value) => {
        value=value.trim()
        setOptions([]);
        if (value && value.length>2) {
            if(options[0] && options[0]["value"] && options[0]["value"][0] && !options[0]["value"][0].includes('No results')){
                if(options[0] && options[0]["value"] && options[0]["value"][0] && options[0]["value"][0].toLowerCase().replace(/\s/g, '').includes(value.toLowerCase().replace(/\s/g, ''))){
                console.log(options[0]["value"])
                return
                }
            }
        
            customFetch(`https://database.entvin.com/getCompanyNameOptions?companyInput=${encodeURIComponent(value)}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // Assuming data is an array of autocomplete options
                if(data.length){
                    setOptions(data);
                }else{
                    setOptions([{comp_name:`No results for ${value}`, disabled: true}]);
                }
            })
            .catch((error) => {
                console.error('Error fetching autocomplete options:', error);
                if ( error.response.status === 401) {
                    window.location.reload();
                }

            });
        }else {
            setOptions([]);
        }
    };

    const onSelect = (value) => {
        console.log(value)
        setLoading(true)
        const selectedOption = options.find(option => option.comp_name === value);
        setCompanyName(selectedOption)
        customFetch(`https://database.entvin.com/getCompanyInfo?searchQuery=${encodeURIComponent(JSON.stringify(selectedOption.similar_names))}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                // Assuming data is an array of autocomplete options
                console.log(data)
                setCompanyData(data)
                setLoading(false)

                // setOptions(data.map(i=> {return {"value": i.comp_name}}));
            })
            .catch((error) => {
                console.error('Error fetching autocomplete options:', error);
                setLoading(false)
                if ( error.response.status === 401) {
                    window.location.reload();
                }

            });
    };

    const onTabChange = (key) => {
        console.log(key);
    };

    return (
        <>
            <Header 
                currentSelection ='company'
            />
            <div className="dashboard">
                {/* Search section */}
                <div className="searchbar">
                <div className="label">Find and Explore Companies</div>
                <div className="searchBox">
                    <AutoComplete
                    className="autoComplete"
                    options={options.map(i => ({
                        value: i.comp_name,
                        label: i.comp_name,
                        disabled: i.disabled? i.disabled: false
                    }))}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                    >
                    <Input
                        className="inputBox"
                        placeholder="Search Company Name"
                        prefix={<SearchOutlined />}
                    />
                    </AutoComplete>
                </div>
                </div>
            </div>
            {loading ? ( // Render the Spin component while loading
                <Spin  className="custom-spinner" />
            ) : (
                companyData?(
                    <>
                        <CompanyInfo
                            companyInfo={{
                                company_name:companyName.comp_name,
                                address: companyData.product_info.address,
                                country:companyData.product_info.country,
                                country_code:companyData.product_info.country_code,
                                socials:{
                                    website: companyData.company_contacts.company_info.website_url,
                                    linkedIn:companyData.company_contacts.company_info.linkedin_url,
                                    twitter: companyData.company_contacts.company_info.twitter_url,
                                },
                                company_apollo:{
                                    founder_year:{
                                        image: FoundedYear,
                                        label: "Founder Year",
                                        value: companyData.company_contacts.company_info.founder_year,
                                        backgroundColor:"#E7FAFF"
                                    },
                                    category: {
                                        image: Category,
                                        label: "Category",
                                        value: companyData.company_contacts.company_info.category,
                                        backgroundColor:"#EAECFD"
                                    },
                                    employees:  {
                                        image: Employees,
                                        label: "Estimated Employees",
                                        value: companyData.company_contacts.company_info.employees,
                                        backgroundColor:"#F5EEFD"
                                    },
                                },
                                registrations: companyData.certificates_info
                            }}
                        />
                        <CompanyContacts
                            contactList = {companyData.company_contacts.final_people_data}
                        />
                        <CompanyProducts
                            productList = {companyData.product_info.data}
                        />
                    
                    </>
                ):(<></>)
            )}
        </>
    );
};

export default CompanyPage;
