// third party imports
import React from "react";
import { Button, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

// local repo imports
import {
  BUYERS_BUTTON_STYLING,
  SELLERS_BUTTON_STYLING,
  SELECT_OPTIONS,
} from "./DashboardFilters.constants";
import "./DashboardFilters.css";

const DashboardFilters = (props) => {
  const { dashboardFilters, setDashboardFilters, countries, registrations } = props;
  const { buyers, sellers, selectedCountries, selectedRegistrations } = dashboardFilters;

  const handleButtonChange = (button) => {
    setDashboardFilters({
      ...dashboardFilters,
      [button]: !dashboardFilters[button],
    });
  };
  const handleChangeCountry = (value) => {
    console.log(`selected ${value}`);
    setDashboardFilters({
      ...dashboardFilters,
      selectedCountries: value
    })
  };
  const handleChangeRegistration = (value) => {
    console.log(`selected ${value}`);
    setDashboardFilters({
      ...dashboardFilters,
      selectedRegistrations: value
    })
  };

  return (
    <div className="filters">
      <div className="filterText">Filter by:</div>
      <Button
        className="buttons"
        icon={buyers ? <CheckOutlined /> : null}
        style={buyers ? BUYERS_BUTTON_STYLING : {}}
        onClick={() => handleButtonChange("buyers")}
      >
        Buyers
      </Button>
      <Button
        className="buttons"
        icon={sellers ? <CheckOutlined /> : null}
        style={sellers ? SELLERS_BUTTON_STYLING : {}}
        onClick={() => handleButtonChange("sellers")}
      >
        Sellers
      </Button>
      <Select
        showArrow
        className="dropdowns"
        placeholder="Country"
        options={countries?countries.filter(i=> i.value.length>0):countries}
        onChange={handleChangeCountry}
        mode="multiple"
        maxTagCount={1} // Adjust as needed

      />
      <Select
        showArrow
        className="dropdowns"
        placeholder="Registrations"
        options={registrations?registrations.filter(i=> i.value.length>0):registrations}
        onChange={handleChangeRegistration}
        mode="multiple"
        maxTagCount={1} // Adjust as needed
      />
    </div>
  );
};

export default DashboardFilters;

DashboardFilters.propTypes = {
  dashboardFilters: PropTypes.object,
  setDashboardFilters: PropTypes.func,
};

DashboardFilters.defaultProps = {
  dashboardFilters: {},
  setDashboardFilters: () => {},
};
