// third part imports
import React, {useState} from "react";
import { Space, Table, Tag, AutoComplete, Input, Drawer, Button, Spin, Tooltip } from 'antd';
import { SearchOutlined, ArrowLeftOutlined, ArrowRightOutlined} from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import eyeIcon from "../../assets/Icon.jpg";
import factoryIcon from "../../assets/factoryIcon.jpeg";
import mapIcon from "../../assets/mapIcon.png";
import LinkedinLogo from "../../assets/linkedinLogo.png";
import BulbSVG from  "../../assets/bulbSVG.svg";
import {InfoCircleFilled} from '@ant-design/icons';
import customFetch from '../../utils/customFetchFunc';
import {useChemicalStore} from '../../zustand';
import DownloadButton from "../fileSaver.js";

import './EntityList.css';

const { Column } = Table;

const EntityList = (props) => {
    const {chemicalInfo} = props
    console.log(chemicalInfo)
    const [filterValue, setFilterValue] = useState('');

    const [filterValueP, setFilterValueP] = useState('');
    const [filterValueC, setFilterValueC] = useState('');
    const chemicalSearched = useChemicalStore((state)=>state.chemicalSearched)


    const [filteredData, setFilteredData] = useState([]);
    const [filteredProduct, setFilteredProduct] = useState([]);
    const [filteredContact, setFilteredContact] = useState([]);


    const [open, setOpen] = useState(false);
    const [viewDetailsRow, setViewDetailsRow] = useState('')
    const [productList, setProductList] = useState([])
    const [contactList, setContactList] = useState([])
    const [loadingP, setLoadingP] = useState(false);
    const [loadingC, setLoadingC] = useState(false);
    const [activeTab, setActiveTab] = useState('all')


    // const DownloadButton = ({ data, columns }) => {
    //     const handleDownload = async () => {
    //       try {
    //         const fileHandle = await window.showDirectoryPicker();
    //         const fileStream = await fileHandle.createWritable();
    //         const blob = new Blob([Papa.unparse(data)], { type: 'text/csv' });
    //         await fileStream.write(blob);
    //         await fileStream.close();
    //       } catch (error) {
    //         console.error('Error saving file:', error);
    //       }
    //     };
      
    //     return (
    //       <Button type="primary" onClick={handleDownload}>
    //         Download CSV
    //       </Button>
    //     );
    //   };
      
      
    
    const onClose = () => {
        setOpen(false);
        setProductList([])
        setContactList([])
        setFilteredProduct([])
        setFilteredContact([])
    };

    const onClear = (value) => {
        setFilterValue('')
        setFilteredData([])
    };

    const onSelect = (value) => {
        setFilterValue(value);
        // Filter the data based on the new filter value
        const filtered = chemicalInfo.data.filter((record) => record.company_name === value);
        setFilteredData(filtered);
    };
    
    const onClearP = (value) => {
        setFilterValueP('')
        setFilteredProduct([])
    };

    const onSelectP = (value) => {
        setFilterValueP(value);
        // Filter the data based on the new filter value
        const filtered = productList.filter((record) => (record.chemical_name + (record.cas_number?(" "+ record.cas_number):"")) === value);
        setFilteredProduct(filtered);
    };

    const onClearC = (value) => {
        setFilterValueC('')
        setFilteredContact([])
    };

    const onSelectC = (value) => {
        setFilterValueC(value);
        // Filter the data based on the new filter value
        const filtered = contactList.filter((record) => (record.name +" - "+ record.title) === value);
        setFilteredContact(filtered);
    };

    const getCompanyProductList = (company,fdf)=>{
        setProductList([])
        setLoadingP(true)
        customFetch(`https://database.entvin.com/getCompanyProductList?companyName=${encodeURIComponent(company)}&fdf=${fdf}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((data) => {
            setProductList(data)
            setLoadingP(false)

        })
        .catch((error) => {
            console.error('Error fetching autocomplete options:', error);
            setLoadingP(false)
            if ( error.response.status === 401) {
                window.location.reload();
            }

        });
    }

    const getCompanyContactList = (company)=>{
        setContactList([])
        setLoadingC(true)
        customFetch(`https://database.entvin.com/getContactData?companyName=${encodeURIComponent(company.toLowerCase().trim())}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
            })
            .then((data) => {
            setContactList(data['final_people_data'])
            setLoadingC(false)

        })
        .catch((error) => {
            console.error('Error fetching autocomplete options:', error);
            setLoadingC(false)
            if ( error.response.status === 401) {
                window.location.reload();
            }

        });
    }

    const handleViewDetails = (value)=>{
        console.log(value,'viewDetails')
        setViewDetailsRow(value)
        let fdf = activeTab=='all'?0:1
        getCompanyProductList(value.company_name,fdf)
        getCompanyContactList(value.company_name)
        setOpen(true);

    }


    return (
        <div className="tableWrapper">
            <div className="entityHeader">
                <div className="fdfSwitchTab">
                    <Button className={(activeTab=='all')?'fdfActiveTab':'fdfInactiveTab'} onClick={()=>{setActiveTab('all'); return}}>All</Button>
                    <Button className={(activeTab=='all')?'fdfInactiveTab':'fdfActiveTab'} onClick={()=>{setActiveTab('fdfonly'); return}}>FDF Only</Button>
                </div>
                <div className="searchCompanyBox">
               
                    <AutoComplete
                        className="autoComplete"
                        options={chemicalInfo.data
                            ? Array.from(new Set(chemicalInfo.data.map(entity => JSON.stringify({ value: entity.company_name }))), JSON.parse)
                            : []}
                        onSelect={onSelect}
                        onClear={onClear}
                        allowClear={true}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                        }
                    >
                    <Input
                        className="inputBox"
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                    />
                    </AutoComplete>
                </div>
                
            </div>
         
            <div className="entityTable">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '20px' }}>
                    <DownloadButton columns={[
                        {
                            title: 'Company',
                            dataIndex: 'company_name',
                            key: 'company_name',
                        },
                        {
                            title: 'Product',
                            dataIndex: 'chemical_name',
                            key: 'chemical_name',
                        },
                        {
                            title: 'Proprietary name',
                            dataIndex: 'trade_name',
                            key: 'trade_name',
                        },
                        {
                            title: 'Registered Country',
                            dataIndex: 'country',
                            key: 'country',
                        },
                        {
                            title: 'Type',
                            dataIndex: 'entity_type',
                            key: 'entity_type',
                        },
                        {
                            title: 'Source',
                            dataIndex: 'registrations',
                            key: 'registrations',
                        },
                        // Add other columns as needed
                        ]} data={(activeTab=='all'?chemicalInfo.data: chemicalInfo.data.filter(record=> record.fdf))}
                        csvName={'entvin_'+chemicalSearched+(chemicalInfo.casData?("_"+chemicalInfo.casData.rn):"")+'_companies.csv'}/>
                </div>

                <Table 
                    className="entityListTable"
                    dataSource={filteredData.length ? filteredData : (activeTab=='all'?chemicalInfo.data: chemicalInfo.data.filter(record=> record.fdf))}
                    // pagination={{
                    //     pageSize: 5,
                    //     total: chemicalInfo.data?chemicalInfo.data.length:0,
                    //     showSizeChanger: false,
                    //     defaultCurrent: 1,
                    //     position: ['bottomCenter'],
                    //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                    //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                    // }}
                    pagination={false}
                    scroll={{
                        y: 525,
                    }}
                   
                >
                    <Column 
                        title="Company" 
                        dataIndex="company_name" 
                        key="company_name"
                        ellipsis = {{
                            showTitle: false,
                        }}
                        render={(company_name)=>(
                            <div className="companyNameTable">
                                <Tooltip placement="topLeft" title={company_name}>
                                    {company_name}
                                </Tooltip>
                            </div>

                        )}
                        width="23%"
                        height="72px"
                    />
                    {
                        activeTab=='all'?(
                            <Column title="Product description" dataIndex="chemical_name" key="chemical_name"  width="20%"
                                render={(chemical_name, record)=>(
                                    <div className="chemicalTable">
                                        <div className="productDescription">
                                            <Tooltip placement="topLeft" title={chemical_name} >
                                                {chemical_name+" "}
                                            </Tooltip>
                                        </div>
                                        <div>
                                            {record['is_similar_name']?(
                                                <Tooltip placement="topLeft" title={`The Product Description contains ${record['similar_name_match']}`}>
                                                    <InfoCircleFilled/>
                                                </Tooltip>
                                            ):(<></>)}
                                        </div>
                                        
                                    </div>

                                )}
                            />
                        ):(
                            <>
                                <Column title="Ingredient Name" dataIndex="chemical_name" key="chemical_name"  width="15%"
                                    render={(chemical_name, record)=>(

                                        record.registrations==="Global trade analytics"?(
                                            <div className="chemicalTable">
                                                <div className="productDescription">
                                                    <Tooltip placement="topLeft" title={chemicalSearched} >
                                                        {chemicalSearched+" "}
                                                    </Tooltip>
                                                </div>
                        
                                            
                                            </div>
                                        ):(
                                            <div className="chemicalTable">
                                                <div className="productDescription">
                                                    <Tooltip placement="topLeft" title={chemical_name} >
                                                        {chemical_name+" "}
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    {record['is_similar_name']?(
                                                        <Tooltip placement="topLeft" title={`The Product Description contains ${record['similar_name_match']}`}>
                                                            <InfoCircleFilled/>
                                                        </Tooltip>
                                                    ):(<></>)}
                                                </div>
                                                
                                            </div>
                                        )
                                       

                                    )}
                                />
                                <Column title="Proprietary name" dataIndex="trade_name" key="trade_name"  width="15%"
                                    render={(trade_name, record)=>(
                                        <div className="chemicalTable">
                                            <div className="productDescription">
                                                <Tooltip placement="topLeft" title={trade_name} >
                                                    {trade_name+" "}
                                                </Tooltip>
                                            </div>
                                        </div>

                                    )}
                                />
                            </>
                        )
                    }
                    
                    <Column
                        title="Registered Country"
                        dataIndex="country"
                        key="country"
                        render={(text, record) => (
                            <>
                                <div className="countrySection">
                                    <div
                                        style={{
                                        width: "32px",
                                        height: "32px",
                                        borderRadius: "50%", // Makes it circular
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden", // Ensures the flag is not distorted
                                        }}
                                    >
                                         <ReactCountryFlag
                                            countryCode={record.country_code}
                                            svg
                                            style={{
                                                width: "43px",
                                                height: "32px"
                                            }}
                                        />
                                    </div>
                                    <div className="countryTable">{ record.country}</div>
                                    
                                </div>
                            
                            </>
                    )}
                    width="17.6%"
                    />
                    {
                        activeTab=='all'?(
                            <Column
                                title="Type"
                                dataIndex="entity_type"
                                key="entity_type"
                                render={(entity_type, record) => (
                                    <>
                                        <Tag  key={entity_type} className={entity_type+"Class"}>
                                            
                                            {entity_type=='buyer'?'Buyer':(entity_type=='seller'?'Seller':'Buyer/Seller')}
                                        </Tag>
                                        {record.fdf?(
                                            <Tag  key='fdf' className="FDFClass">
                                                FDF
                                            </Tag>
                                        ):(<></>)}
                                        {record.oro_flag?(
                                            <Tag  key='OR' className="FDFClass">
                                                OR
                                            </Tag>
                                        ):(<></>)}
                                        {record.who_gmp?(
                                            <Tag  key='who_gmp' className="FDFClass">
                                                WHO GMP
                                            </Tag>
                                        ):(<></>)}
                                    </>
                                )}
                            />
                        ):(<></>)
                    }
                    
                    <Column
                        title="Source"
                        dataIndex="registrations"
                        key="registrations"
                        render={(registrations, record) => (
                            (registrations=='')?
                                (<></>)
                            :(
                            <>
                            <Tag className="registrationsTable" key={registrations}>
                                <Tooltip placement="topLeft" title={record.registrations_hover}>
                                    {registrations}
                                </Tooltip>
                            </Tag>
                            </>)
                        )}
                    />
                    <Column
                        key="action"
                        render={(_, record) => (
                            <Space size="middle">
                            <a
                                 href="#"
                                 onClick={(e) => {
                                   e.preventDefault(); // Prevent default link behavior
                                   handleViewDetails(record); // Pass arguments here
                                 }}
                            > <div className="viewDetailsTable"> <img src={eyeIcon}></img>  View Details</div></a>
                        
                            </Space>
                        )}
                    />
                </Table>   
            </div>
            {activeTab=='all'?(
                <div className="drawerDiv">
                    {console.log(Math.min(900, window.innerHeight))}
                    <Drawer
                        placement="right"
                        size="large"
                        onClose={onClose}
                        open={open}
                        width="66.66%"
                        height={Math.min(900, window.innerHeight)}
                        style={{ minWidth:"880px", maxHeight:"100%" }}
                        // Adjust the radius value as needed
                    >
                        <>
                            {(loadingP || loadingC) ? ( // Render the Spin component while loading
                                <Spin  className="custom-spinner" />
                                ) : (
                            <div className="fullPage">
                                <div className="headerTile">
                                    <div className="chemicalRow">
                                        <div className="chemicalNameRow">
                                            <Tooltip placement="topLeft" title={viewDetailsRow.chemical_name}>
                                                {viewDetailsRow.chemical_name}
                                            </Tooltip>
                                        </div>
                                        <div className="chemicalRowDetails">
                                            <Tag  key={viewDetailsRow.entity_type} className={viewDetailsRow.entity_type+"Class"}>
                                                
                                                {viewDetailsRow.entity_type=='buyer'?'Buyer':(viewDetailsRow.entity_type=='seller'?'Seller':'Buyer/Seller')}
                                            </Tag>
                                            <div className="countrySection">
                                                <div
                                                    style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    borderRadius: "50%", // Makes it circular
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    overflow: "hidden", // Ensures the flag is not distorted
                                                    }}
                                                >
                                                    <ReactCountryFlag
                                                        countryCode={viewDetailsRow.country_code}
                                                        svg
                                                        style={{
                                                            width: "35px",
                                                            height: "24px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="countryTable">{ viewDetailsRow.country}</div>
                                                
                                            </div>

                                        </div>
                                    </div>

                                    {viewDetailsRow.registrations==''?
                                        (<></>)
                                    :(
                                    <>
                                        <div className="certificatesRow">
                                            <Tag className="registrationsTable" key={viewDetailsRow.registrations}>
                                                <Tooltip placement="topLeft" title={viewDetailsRow.registrations_hover}>
                                                    {viewDetailsRow.registrations}
                                                </Tooltip>
                                            </Tag>             
                                        </div>
                                    </>)
                                    }
                                    <div style={{height: "1px", width:"100%", margin: "0 auto", background:"#EAECF0"}}></div>
                                    <div className="companyInfoRow">
                                        <div className="companyNameRow">
                                            <img src={factoryIcon} style={{background:"#7F56D9", height:"36px", borderRadius:"50%", marginRight:"12px"}}></img>
                                            {viewDetailsRow.company_name}
                                        </div>
                                        { viewDetailsRow.address!=null?(
                                            <div style={{ height: "48px", display:"flex", marginBottom:"28px"}}>
                                                <div style={{height:"48px"}}>
                                                    <img src={mapIcon} style={{marginRight: "22px", height:"16px", marginLeft:"10px", marginTop:"16px"}}></img>
                                                </div>
                                                
                                                <div className="companyAddressRow">
                                                    <div className="manufacturingAddress">
                                                        {viewDetailsRow.table=='ECDATA'?"Manufacturing plant address":"Company address"}
                                                    </div>
                                                    <div className="addressRow">
                                                        {viewDetailsRow.address}
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            ):(<></>)
                                        }
                                        {viewDetailsRow.capacity?(
                                            <div className="capacityTag">
                                                <img  style={{ maxWidth: "24px", maxHeight: "24px" }} src={BulbSVG} alt="Molecular Structure" /> 
                                                <div className="capacityTagText">
                                                    This manufacturing site has production capacity of 
                                                    <span style={{ fontWeight: "700"}}>{` ${viewDetailsRow.capacity} `}</span>
                                                    for {viewDetailsRow.chemical_name}
                                                </div>
                                            </div>
                                            ):(
                                                <></>
                                            )
                                        }
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                {(viewDetailsRow.entity_type=='buyer' || (productList.length==0))?(<></>
                                ):
                                (
                                    <div className="productsDiv">
                                        <div className="tableTitle">
                                            <div style={{marginRight: '18px', fontSize: '14px', lineHeight:'36px', height:'44px', paddingTop:'4px'}}>Product List</div>
                                            <div className="searchCompanyBox1">
                                                <AutoComplete
                                                    className="autoComplete"
                                                    options={productList
                                                        ? Array.from(new Set(productList.map(entity => JSON.stringify({ value: entity.chemical_name + (entity.cas_number?(" "+entity.cas_number):"") }))), JSON.parse)
                                                        : []}
                                                    onSelect={onSelectP}
                                                    onClear={onClearP}
                                                    allowClear={true}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                >
                                                <Input
                                                    className="inputBox"
                                                    placeholder="Search Chemical Name Or Cas Number"
                                                    prefix={<SearchOutlined />}
                                                />
                                                </AutoComplete>
                                            </div>
                                        </div>
                                        <Table 
                                            dataSource={filteredProduct.length ? filteredProduct : productList}
                                            // pagination={{
                                            //     pageSize: 5,
                                            //     total: productList?productList.length:0,
                                            //     showSizeChanger: false,
                                            //     defaultCurrent: 1,
                                            //     position: ['bottomCenter'],
                                            //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                                            //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                                            // }}
                                            pagination={false}
                                                scroll={{
                                                    y: 400,
                                            }}
                                            // scroll={{ y: '400px' }}
                                        >
                                        
                                            <Column title="Chemical" dataIndex="chemical_name" key="chemical_name"  width="33%"
                                                render={(chemical_name)=>(
                                                    <div className="chemicalTable">
                                                        <Tooltip placement="topLeft" title={chemical_name}>
                                                            {chemical_name}
                                                        </Tooltip>
                                                    </div>

                                                )}
                                            />
                                                <Column title="CAS" dataIndex="cas_number" key="cas_number"  width="33%"
                                                render={(cas_number)=>(
                                                    <div className="chemicalTable">
                                                        <Tooltip placement="topLeft" title={cas_number}>
                                                            {cas_number}
                                                        </Tooltip>
                                                    </div>

                                                )}
                                            />
                                    
                                        
                                            <Column
                                                title="Source"
                                                dataIndex="registrations"
                                                key="registrations"
                                                width="33%"
                                                render={(registrations) => (
                                                    (registrations=='')?
                                                        (<></>)
                                                    :(
                                                    <>
                                                    <Tag className="registrationsTable" key={registrations}>
                                                        {registrations}
                                                    </Tag>
                                                    </>)
                                                )}
                                            />
                                    
                                        </Table> 
                                    </div>
                                )}
                                {
                                    contactList.length>0?(
                                        <div className="contacsDiv">
                                            <div className="tableTitle">
                                                <div style={{marginRight: '18px', fontSize: '14px', lineHeight:'36px', height:'44px', paddingTop:'4px'}}>Decision Makers</div>
                                                <div className="searchCompanyBox1">
                                                    <AutoComplete
                                                        className="autoComplete"
                                                        options={contactList
                                                            ? Array.from(new Set(contactList.map(entity => JSON.stringify({ value: entity.name + " - "+ entity.title }))), JSON.parse)
                                                            : []}
                                                        onSelect={onSelectC}
                                                        onClear={onClearC}
                                                        allowClear={true}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                    >
                                                    <Input
                                                        className="inputBox"
                                                        placeholder="Search via Name or Title"
                                                        prefix={<SearchOutlined />}
                                                    />
                                                    </AutoComplete>
                                                </div>
                                            </div>  
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '20px' }}>
                                                <DownloadButton columns={[
                                                    {
                                                        title: 'Name',
                                                        dataIndex: 'name',
                                                        key: 'name',
                                                    },
                                                    {
                                                        title: 'Title',
                                                        dataIndex: 'title',
                                                        key: 'title',
                                                    },
                                                    {
                                                        title: 'Socials',
                                                        dataIndex: 'linkedIn',
                                                        key: 'linkedIn',
                                                    },{
                                                        title: 'Company',
                                                        dataIndex: 'companyName',
                                                        key: 'companyName',
                                                    }
                                                    // Add other columns as needed
                                                    ]} data={contactList}
                                                csvName={'entvin_'+chemicalSearched+(chemicalInfo.casData?("_"+chemicalInfo.casData.rn):"")+'_contacts.csv'}/>

                                            </div>      
                                            <Table 
                                                dataSource={filteredContact.length ? filteredContact : contactList}

                                                // pagination={{
                                                //     pageSize: 5,
                                                //     total: contactList?contactList.length:0,
                                                //     showSizeChanger: false,
                                                //     defaultCurrent: 1,
                                                //     position: ['bottomCenter'],
                                                //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                                                //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button
            
                                                // }}
                                                pagination={false}
                                                scroll={{
                                                    y: 400,
                                                }}
                                                // scroll={{ y: '400px' }}
                                            >
                                            
                                                <Column title="Name" dataIndex="name" key="name"  width="20%"
                                                    render={(name)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={name}>
                                                                {name}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Title" dataIndex="title" key="title"  width="20%"
                                                    render={(title)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={title}>
                                                                {title}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                {/* <Column title="Email" dataIndex="email" key="email"  width="20%"
                                                    render={(email)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={email}>
                                                                {email}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Phone" dataIndex="phone" key="phone"  width="20%"
                                                    render={(phone)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={phone}>
                                                                {phone}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                /> */}
                                                <Column title="Socials" dataIndex="linkedIn" key="linkedIn"  width="20%"
                                                    render={(linkedIn)=>(
                                                        <div className="chemicalTable">
                                                            <a href={linkedIn} target="_blank" ><img src={LinkedinLogo}/></a>
                                                        </div>
            
                                                    )}
                                                />
                                            
                                                
                                        
                                            </Table> 
                                        </div>
                                    ):(
                                        <></>
                                    )
                                }
                                
                            </div>
                            )}
                        </>
                    </Drawer>
                </div>
                ):(
                    <div className="drawerDiv">
                    {console.log(Math.min(900, window.innerHeight))}
                    <Drawer
                        placement="right"
                        size="large"
                        onClose={onClose}
                        open={open}
                        width="66.66%"
                        height={Math.min(900, window.innerHeight)}
                        style={{ minWidth:"880px", maxHeight:"100%" }}
                        // Adjust the radius value as needed
                    >
                        <>
                            {(loadingP || loadingC) ? ( // Render the Spin component while loading
                                <Spin  className="custom-spinner" />
                                ) : (
                            <div className="fullPage">
                                <div className="headerTile">
                                    <div className="chemicalRow">
                                        <div className="chemicalNameRow">
                                            <Tooltip placement="topLeft" title={viewDetailsRow.trade_name}>
                                                {viewDetailsRow.trade_name}
                                            </Tooltip>
                                        </div>
                                        <div className="chemicalRowDetails">
                                            <div className="countrySection">
                                                <div
                                                    style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    borderRadius: "50%", // Makes it circular
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    overflow: "hidden", // Ensures the flag is not distorted
                                                    }}
                                                >
                                                    <ReactCountryFlag
                                                        countryCode={viewDetailsRow.country_code}
                                                        svg
                                                        style={{
                                                            width: "35px",
                                                            height: "24px"
                                                        }}
                                                    />
                                                </div>
                                                <div className="countryTable">{ viewDetailsRow.country}</div>
                                                
                                            </div>

                                        </div>
                                    </div>

                                    {viewDetailsRow.registrations==''?
                                        (<></>)
                                    :(
                                    <>
                                        <div className="certificatesRow">
                                            <Tag className="registrationsTable" key={viewDetailsRow.registrations}>
                                                <Tooltip placement="topLeft" title={viewDetailsRow.registrations_hover}>
                                                    {viewDetailsRow.registrations}
                                                </Tooltip>
                                            </Tag>             
                                        </div>
                                    </>)
                                    }
                                    <div style={{height: "1px", width:"100%", margin: "0 auto", background:"#EAECF0"}}></div>
                                    <div className="companyInfoRow">
                                        <div className="companyNameRow">
                                            <img src={factoryIcon} style={{background:"#7F56D9", height:"36px", borderRadius:"50%", marginRight:"12px"}}></img>
                                            {viewDetailsRow.company_name}
                                        </div>
                                        { viewDetailsRow.address!=null?(
                                            <div style={{ height: "48px", display:"flex", marginBottom:"28px"}}>
                                                <div style={{height:"48px"}}>
                                                    <img src={mapIcon} style={{marginRight: "22px", height:"16px", marginLeft:"10px", marginTop:"16px"}}></img>
                                                </div>
                                                
                                                <div className="companyAddressRow">
                                                    <div className="manufacturingAddress">
                                                        {viewDetailsRow.table=='ECDATA'?"Manufacturing plant address":"Company address"}
                                                    </div>
                                                    <div className="addressRow">
                                                        {viewDetailsRow.address}
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            ):(<></>)
                                        }
                                        {viewDetailsRow.approval_date?(
                                            <div className="capacityTagFDF">
                                                <img  style={{ maxWidth: "24px", maxHeight: "24px" }} src={BulbSVG} alt="Info" /> 
                                                <div className="capacityTagTextFDF">
                                                    Dosage is approved on: {viewDetailsRow.approval_date}<br></br>
                                                    {viewDetailsRow.strength?`The dosage strength is ${viewDetailsRow.strength} in ${viewDetailsRow.df_route.split(';')[0]} form.`:''}<br></br>
                                                    {viewDetailsRow.df_route?`The route of dosage is ${viewDetailsRow.df_route.split(';')[1]}`:''}

                                                </div>
                                            </div>
                                            ):(
                                                <></>
                                            )
                                        }
                                        <div>

                                        </div>
                                    </div>
                                </div>
                                {((productList.length==0))?(<></>
                                ):
                                (
                                    <div className="productsDiv">
                                        <div className="tableTitle">
                                            <div style={{marginRight: '18px', fontSize: '14px', lineHeight:'36px', height:'44px', paddingTop:'4px'}}>Product List</div>
                                            <div className="searchCompanyBox1">
                                                <AutoComplete
                                                    className="autoComplete"
                                                    options={productList
                                                        ? Array.from(new Set(productList.map(entity => JSON.stringify({ value: entity.chemical_name + (entity.cas_number?(" "+entity.cas_number):"") }))), JSON.parse)
                                                        : []}
                                                    onSelect={onSelectP}
                                                    onClear={onClearP}
                                                    allowClear={true}
                                                    filterOption={(inputValue, option) =>
                                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                >
                                                <Input
                                                    className="inputBox"
                                                    placeholder="Search"
                                                    prefix={<SearchOutlined />}
                                                />
                                                </AutoComplete>
                                            </div>
                                        </div>
                                        <Table 
                                            dataSource={filteredProduct.length ? filteredProduct : productList}
                                            // pagination={{
                                            //     pageSize: 5,
                                            //     total: productList?productList.length:0,
                                            //     showSizeChanger: false,
                                            //     defaultCurrent: 1,
                                            //     position: ['bottomCenter'],
                                            //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                                            //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                                            // }}
                                            pagination={false}
                                                scroll={{
                                                    y: 400,
                                            }}
                                            // scroll={{ y: '400px' }}
                                        >
                                        
                                            <Column title="Finished Drugs" dataIndex="chemical_name" key="chemical_name"  width="60%"
                                                render={(chemical_name)=>(
                                                    <div className="chemicalTable">
                                                        <Tooltip placement="topLeft" title={chemical_name}>
                                                            {chemical_name}
                                                        </Tooltip>
                                                    </div>

                                                )}
                                            />
                                    
                                        
                                            <Column
                                                title="Source"
                                                dataIndex="registrations"
                                                key="registrations"
                                                width="40%"
                                                render={(registrations) => (
                                                    (registrations=='')?
                                                        (<></>)
                                                    :(
                                                    <>
                                                    <Tag className="registrationsTable" key={registrations}>
                                                        {registrations}
                                                    </Tag>
                                                    </>)
                                                )}
                                            />
                                    
                                        </Table> 
                                    </div>
                                )}
                                {
                                    contactList.length>0?(
                                        <div className="contacsDiv">
                                            <div className="tableTitle">
                                                <div style={{marginRight: '18px', fontSize: '14px', lineHeight:'36px', height:'44px', paddingTop:'4px'}}>Decision Makers</div>
                                                <div className="searchCompanyBox1">
                                                    <AutoComplete
                                                        className="autoComplete"
                                                        options={contactList
                                                            ? Array.from(new Set(contactList.map(entity => JSON.stringify({ value: entity.name + " - "+ entity.title }))), JSON.parse)
                                                            : []}
                                                        onSelect={onSelectC}
                                                        onClear={onClearC}
                                                        allowClear={true}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                    >
                                                    <Input
                                                        className="inputBox"
                                                        placeholder="Search via Name or Title"
                                                        prefix={<SearchOutlined />}
                                                    />
                                                    </AutoComplete>
                                                </div>
                                            </div>        
                                            <Table 
                                                dataSource={filteredContact.length ? filteredContact : contactList}

                                                // pagination={{
                                                //     pageSize: 5,
                                                //     total: contactList?contactList.length:0,
                                                //     showSizeChanger: false,
                                                //     defaultCurrent: 1,
                                                //     position: ['bottomCenter'],
                                                //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                                                //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button
            
                                                // }}
                                                pagination={false}
                                                scroll={{
                                                    y: 400,
                                                }}
                                                // scroll={{ y: '400px' }}
                                            >
                                            
                                                <Column title="Name" dataIndex="name" key="name"  width="20%"
                                                    render={(name)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={name}>
                                                                {name}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Title" dataIndex="title" key="title"  width="20%"
                                                    render={(title)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={title}>
                                                                {title}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Email" dataIndex="email" key="email"  width="20%"
                                                    render={(email)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={email}>
                                                                {email}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Phone" dataIndex="phone" key="phone"  width="20%"
                                                    render={(phone)=>(
                                                        <div className="chemicalTable">
                                                            <Tooltip placement="topLeft" title={phone}>
                                                                {phone}
                                                            </Tooltip>
                                                        </div>
            
                                                    )}
                                                />
                                                <Column title="Socials" dataIndex="linkedIn" key="linkedIn"  width="20%"
                                                    render={(linkedIn)=>(
                                                        <div className="chemicalTable">
                                                            <a href={linkedIn} target="_blank" ><img src={LinkedinLogo}/></a>
                                                        </div>
            
                                                    )}
                                                />
                                            
                                                
                                        
                                            </Table> 
                                        </div>
                                    ):(
                                        <></>
                                    )
                                }
                                
                            </div>
                            )}
                        </>
                    </Drawer>
                    </div>
                )
            }
            
        </div>
    )
};

export default EntityList;

EntityList.propTypes = {
};

EntityList.defaultProps = {
};