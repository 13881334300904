// third party imports
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input, Tabs, Card, Spin } from "antd";
import {useChemicalStore} from '../../zustand';
import customFetch from '../../utils/customFetchFunc';
import { useLocation } from 'react-router-dom';

// local repo imports
import Header from "../../components/Header";

import "./Home.css";

const Home = () => {
  useEffect(() => {}); // useEffect will run whenever modalData is updated

  const [options, setOptions] = useState([]);
  const [loading, setLoading]=useState(false)
  const setChemicalSearched = useChemicalStore((state)=>state.setChemicalSearched)
  const setChemicalInfo = useChemicalStore((state)=>state.setChemicalInfo)

  const navigate = useNavigate();
 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(typeof queryParams.get('pdf'),'pdf')


  const handleSearch = (value) => {
    value=value.trim()
    if (value&& value.length>2) {
      // Make an API call to fetch autocomplete options based on the value
      if(options[0] && options[0]["value"] && options[0]["value"][0]&&!options[0]["value"][0].includes('No results')){
        if(options[0] && options[0]["value"] && options[0]["value"][0] && options[0]["value"][0].toLowerCase().replace(/\s/g, '').includes(value.toLowerCase().replace(/\s/g, ''))){
          console.log(options[0]["value"])
          return
        }
      }
      customFetch(`https://database.entvin.com/getAutoCompleteOptions?chemicalInput=${encodeURIComponent(value)}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log(data)
          // Assuming data is an array of autocomplete options
          if(data.length){
            setOptions(data.map(i=> {return {"value": [i.chemical_name, i.cas_number],
              "label":(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                <span  style={{textOverflow: "ellipsis",whiteSpace: "normal", overflow: "hidden"}}>
                  {i.chemical_name}
                </span>
                <span style={{ width: '20px' }}></span> {/* Adjust the width as per your requirement */}
                <span>{i.cas_number?i.cas_number:""}</span>
              </div>
            )
            }}));
          }else{
            setOptions([{"value": [`No results for ${value}`],
              "label":(
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                <span>
                  {`No results for ${value}`}
                </span>
              </div>
              ),
              "disabled":true
            }]);
          }
        })
        .catch((error) => {
          console.error('Error fetching autocomplete options:', error);
          if ( error.response.status === 401) {
            window.location.reload();
          }
        });
    } else {
      setOptions([]);
    }
  };
  const onSelect = (value) => {
    console.log("onSelect11111", value);
    setLoading(true); // Set loading to true
    setChemicalSearched(value[0])
    if(value[0].length==0){
      return
    }
    customFetch(`https://database.entvin.com/getChemicalData?chemicalName=${encodeURIComponent(value[0])}&casNo=${encodeURIComponent(value[1])}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      console.log(data)
      setChemicalInfo(data)

      queryParams.get('pdf')=='1'?navigate('/dashboard?pdf=1'):navigate('/dashboard')


      setLoading(false); // Set loading back to false when API call is complete

      // Assuming data is an array of autocomplete options
      // setOptions(data.map(i=> {return {"value": i}}));
    })
    .catch((error) => {
      console.error('Error fetching autocomplete options:', error);
      setLoading(false); // Set loading back to false when API call is complete
      if ( error.response.status === 401) {
        window.location.reload();
      }

    });
  };

  
  return (
    <>
    {loading ? ( // Render the Spin component while loading
          <Spin  className="custom-spinner" />
        ) : (
      <>
        <Header 
          currentSelection ='chemical'
        />
        <div className="dashboard">
          {/* Search section */}
          <div className="searchbar">
            <div className="label">Find and Explore Chemicals</div>
            <div className="searchBox">
              <AutoComplete
                className="autoComplete"
                options={options}
                onSelect={onSelect}
                onSearch={handleSearch}
              >
                <Input
                  className="inputBox"
                  placeholder="Search Chemical Name or CAS number"
                  prefix={<SearchOutlined />}
                />
              </AutoComplete>
            </div>
            {/* <div className="searchTagline">
              Uncertain about the next steps? Let us help you with this!
            </div> */}
          </div>
        </div>
      </>
    )
  }
  </>
  );
};

export default Home;
