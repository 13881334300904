import { create } from 'zustand'

const useChemicalStore = create((set) => ({
  chemicalSearched: '', // Initialize with a default value
  chemicalInfo: {data:[], casData:null}, // Initialize with an empty array
  setChemicalSearched: (chemicalSearched) => set({ chemicalSearched }),
  setChemicalInfo: (chemicalInfo) => set({ chemicalInfo }),
}));


export {useChemicalStore};
