// third party imports
import React, { useState, useEffect } from "react";

// local repo imports
import Header from "../../components/Header";


const Pricing = () => {

  
  return (
    <div style={{width:"100%", height:window.innerHeight}}>
        <Header 
            currentSelection ='pricing'
        />
       <iframe
            src="https://entvin.metabaseapp.com/public/dashboard/1544c718-f833-4e62-b518-e94dcf165267"
            frameborder="0"
            width="100%"
            height="100%"
            allowtransparency
        ></iframe>
      </div>
  );
};

export default Pricing;
