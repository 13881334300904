import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'; // Import Mapbox Draw CSS
import './Map.css'
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore 
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const {markers} = props
  const markersRef = useRef([]);


  useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoia3NoaXRpai1lbnR2aW4iLCJhIjoiY2xtYzZvcHBiMGFvMzNlcDRsMjU1NjRnZCJ9.nytHe_9D6ZcgUWNmwQ7iFA';

    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/navigation-night-v1', // Use the "Mapbox Dark" style
      center: [-26.3351, 26.3351], // Initial map center [longitude, latitude] //26.3351° N, 26.3351
      zoom: 1, // Initial zoom level
    });

    // Add navigation controls to the map
    map.current.addControl(new mapboxgl.NavigationControl());

    console.log(markers,'nnnnnnnnn')
    // Add markers to the map with popups
    markers.forEach((marker) => {
      const { lng, lat, color, number } = marker;
      // const popup = new mapboxgl.Popup().setText(entityName);

      const markerElement = document.createElement('div');
      markerElement.className = 'custom-marker';
      markerElement.textContent = number;
      markerElement.style.backgroundColor=color
      markerElement.style.boxShadow = `0 0 5px ${color}, 0 0 20px ${color}`

 

      // Create a marker and set its element
      const newMarker = new mapboxgl.Marker(markerElement)
        .setLngLat([lng, lat])
        // .setPopup(new mapboxgl.Popup().setHTML(entityName))
        .addTo(map.current);
      markersRef.current.push(newMarker);

    });

    // Clean up when the component unmounts
    return () => map.current.remove();
  }, []);

  useEffect(()=>{
    console.log(markers,'mmmmmmmm')
    markersRef.current.forEach((marker) => marker.remove());
    markersRef.current = [];
    markers.forEach((marker) => {
      const { lng, lat, color, number } = marker;
      // const popup = new mapboxgl.Popup().setText(entityName);

      
      const markerElement = document.createElement('div');
      markerElement.className = 'custom-marker';
      markerElement.textContent = number;
      markerElement.style.backgroundColor=color
      markerElement.style.boxShadow = `0 0 5px ${color}, 0 0 20px ${color}`
 

      // Create a marker and set its element
      const newMarker = new mapboxgl.Marker(markerElement)
        .setLngLat([lng, lat])
        // .setPopup(new mapboxgl.Popup().setHTML(entityName))
        .addTo(map.current);
      markersRef.current.push(newMarker);

    });

  }, [markers])
  return (
    <div>
      <div ref={mapContainerRef} style={{ height: '401px', width: '100%', borderRadius: '10px' }} />
    </div>
  );
};

export default Map;
