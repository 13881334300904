import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle,  registerWithEmailAndPassword} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { ReactComponent as EntvinHeader }  from "../../assets/entvin_header.svg";
import EntvinLogo from "../../assets/entvin_logo.png"
import GoogleIcon from "../../assets/googleIcon.png"
import { Spin } from "antd";

import { Button, Form, Input} from 'antd';



import "./Auth.css";

function SignIn() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const [activeTab, setActiveTab] = useState('login')
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('vertical');
    const [screenLoading, setScreenLoading] = useState(false);
    const [isBusinessEmail, setIsBusinessEmail] = useState(true); 
    const [emailError, setEmailError] = useState(""); 
    const [showDesktopMessage, setShowDesktopMessage] = useState(false);

    useEffect(() => {
        // Check if the screen width is less than a certain threshold (e.g., 768px for tablets)
        const isMobile = window.innerWidth < 768;

        // Set the state to show the message if it's a mobile device
        setShowDesktopMessage(isMobile);
    }, []);


    useEffect( () => {
        if (loading) {
        // maybe trigger a loading screen
        console.log(loading)
        return;
        }
        if (user) {
            console.log(user)
            user.getIdToken(true).then(idToken=>{
                localStorage.setItem('idToken',idToken)
            })
            navigate("/")
        }
        
    }, [user, loading]);

    const onSubmitSignIn = (values) => {
        console.log('Success:', values);
        const { email, password } = values;
        console.log(email, password)
        logInWithEmailAndPassword(email, password)
        // setScreenLoading(true)

    };
  
    const onSubmitFailedSignIn = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // setScreenLoading(false)

    };
    const onSubmitSignUp = (values) => {
        console.log('Success:', values);





        const { name, email, password } = values;

        const businessEmailRegex = /^[a-zA-Z0-9._%+-]+@(?!gmail\.com|yahoo\.com|hotmail\.com|outlook\.com|aol\.com|icloud\.com|protonmail\.com)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        const isValidEmail = businessEmailRegex.test(email);

        if(!isValidEmail){
            setEmailError( "Invalid business email");
            return
        }
        console.log(email, password)
        registerWithEmailAndPassword(name, email, password)
        // setScreenLoading(true)

    };
    
    const onSubmitFailedSignup = (errorInfo) => {
        console.log('Failed:', errorInfo);
        // setScreenLoading(false)

    };


    return (
        <>
            {showDesktopMessage ? (
                <div style={{
                    position: 'fixed',
                    top: '33%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: '10px',
                    background: '#f0f0f0',
                    width: '80%',
                    maxWidth: '400px',
                    zIndex: '999',
                    backgroundColor:'white',
                    fontSize:'20px'
                }}>                    
                    <p>Please open the link on a laptop/desktop</p>
                </div>
            ) : (
                <>
            
                {screenLoading?(
                        <Spin  className="custom-spinner" />
                    ):(
                        <div className="authPage">
                            <div className="authPageheader">
                                <EntvinHeader style={{height:"100%"}}/>
                            </div>
                            <div className="authPageBody">
                                <div className="backgroundDiv"></div>
                                <div className="authImgIcon">
                                    <img src={EntvinLogo} style={{height:"60px", width:"60px"}}></img>
                                </div>
                                <div className="authSwitchTab">
                                    <Button className={(activeTab=='login')?'authActiveTab':'authInactiveTab'} onClick={()=>{setActiveTab('login'); return}}>Log in</Button>
                                    <Button className={(activeTab=='login')?'authInactiveTab':'authActiveTab'} onClick={()=>{setActiveTab('signup'); return}}>Sign up</Button>
                                </div>
                                <>
                                    {activeTab=='login'?(
                                        <>
                                            <div className="authPageTitle">
                                                Log in to your account
                                            </div>
                                            <div className="authPagesubTitle">
                                                Welcome back! Please enter your details.                        
                                            </div>
                                            <Form
                                                layout={formLayout}
                                                form={form}
                                                initialValues={{
                                                    layout: formLayout,
                                                }}
                                                // onValuesChange={onFormLayoutChange}
                                                style={{
                                                    width: "360px",
                                                    margin: '0 auto',
                                                }}
                                                onFinish={onSubmitSignIn}
                                                onFinishFailed={onSubmitFailedSignIn}
                                                autoComplete="off"
                                            >
                                            
                                                <Form.Item className="formLabelStyle" label="Email" name="email">
                                                    <Input className="formInputStyle" type="email" placeholder="Enter your email" />
                                                </Form.Item>
                                                <Form.Item className="formLabelStyle" label="Password" name="password">
                                                    <Input className="formInputStyle" type="password" placeholder="********" />
                                                </Form.Item>
                                                <Form.Item  className="forgotPassword">
                                                    <a style={{ color: "#6941C6", fontSize:"14px"}}>Forgot password</a>
                                                </Form.Item>
                                                <Form.Item >
                                                    <Button className="buttonStyle" type="primary" htmlType="submit">Sign in</Button>
                                                </Form.Item>
                                            </Form>
                                            {/* <Button className="googleLoginButton" onClick={signInWithGoogle}> <img src={GoogleIcon} style={{height:"24px", width:"24px", marginRight:"12px"}}></img>Sign in with Google</Button> */}
                                            <div className="authPageBottom">
                                                Don’t have an account?<button style={{ color: "#6941C6", background: "transparent", border:"none"}} type="primary" onClick={()=>setActiveTab('signup')}> Sign up</button>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            <div className="authPageTitle">
                                                Create a new account                       
                                            </div>
                                            <div className="authPagesubTitle">
                                                Start your journey with Entvin                        
                                            </div>
                                            <Form
                                                layout={formLayout}
                                                form={form}
                                                initialValues={{
                                                    layout: formLayout,
                                                }}
                                                // onValuesChange={onFormLayoutChange}
                                                style={{
                                                    width: "360px",
                                                    margin: '0 auto',
                                                }}
                                                onFinish={onSubmitSignUp}
                                                onFinishFailed={onSubmitFailedSignup}
                                            >
                                            <Form.Item className="formLabelStyle" rules={[{ required: true, message: 'Please input your name!' }]} label="Name" name="name">
                                                    <Input className="formInputStyle" type="text" placeholder="Enter your name" />
                                                </Form.Item>
                                                <Form.Item className="formLabelStyle"  rules={[{ required: true, message: 'Please input your email!' }]}label="Business Email" name="email"
                                                    help={emailError} // Display email error message
                                                    validateStatus={emailError ? "error" : ""} // Set error status if email is invalid
                                                    onChange={(e) => setEmail(e.target.value)} // Add this line

                                                >
                                                    <Input className="formInputStyle" type="email" placeholder="Enter your business email" />
                                                </Form.Item>
                                                <Form.Item className="formLabelStyle"  rules={[{ required: true, message: 'Please input your password!' }]}label="Password" name="password">
                                                    <Input className="formInputStyle" type="password" placeholder="********" />
                                                </Form.Item>
                                                {/* <Form.Item  className="forgotPassword">
                                                    <a style={{ color: "#6941C6", fontSize:"14px"}}>Forgot password</a>
                                                </Form.Item> */}
                                                <Form.Item  style={{marginTop:"24px"}}>
                                                    <Button className="buttonStyle" type="primary" htmlType="submit">Sign up</Button>
                                                </Form.Item>
                                            </Form>
                                            {/* <Button className="googleLoginButton" onClick={signInWithGoogle}> <img src={GoogleIcon} style={{height:"24px", width:"24px", marginRight:"12px"}}></img>Sign in with Google</Button> */}
                                            <div className="authPageBottom">
                                                Already have an account?<button style={{ color: "#6941C6", background: "transparent", border:"none"}} type="primary" onClick={()=>setActiveTab('login')}> Log in</button>
                                            </div>
                                        </>
                                    )}
                                </>
                            </div>
                        </div>
                )}
                </>
            )}
        </>
        
    
    );
}
export default SignIn;