export const DASHBOARD_TABS = [
  {
    key: 1,
    label: "Entity Locations",
  },
  {
    key: 2,
    label: "Entity List",
  },
  // {
  //   key: 3,
  //   label: "Pricing",
  // },
  // {
  //   key: 4,
  //   label: "Routes of Synthesization",
  // },
];

export const CHEMICAL_INFO = [
  {
    image: "",
    label: "Chemical Structure",
    value: "C7H5CIO",
    link: "/",
    linkLabel: "View Full Structure",
  },
  {
    image: "",
    label: "Molecular Weight",
    value: "140.57 g/mol",
  },
  {
    image: "",
    label: "IUPAC",
    value: "${{C}_{6}}{{H}_{5}}COC Long name",
    link: "/",
    linkLabel: "View Full IUPAC",
  },
];

export const CHEMICAL_CHILDREN = [
  "Benzene carbonyl chloride",
  "Benzene carbonyl chloride Benzene carbonyl chloride",
  "Benzene carbonyl chloride Benzene carbonyl chloride",
  "Benzene carbonyl",
  "Benzene carbonyl chloride Benzene carbonyl chloride",
  "Benzene carbonyl chloride Benzene carbonyl chloride",
  "Benzene carbonyl",
];

export const DASHBOARD_FILTERS = {
  buyers: true,
  sellers: true,
  selectedCountries: [],
  selectedRegistrations: [],
};
