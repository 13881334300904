// third part imports
import React, {useState, useEffect} from "react";
import { Table, Tag, AutoComplete, Input,  Tooltip } from 'antd';
import { SearchOutlined,} from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import LinkedinLogo from "../../assets/linkedinLogo.png";

import {InfoCircleFilled} from '@ant-design/icons';

import './CompanyProducts.css';

const { Column } = Table;

const CompanyProducts = (props) => {
    const {productList} = props
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filterValueP, setFilterValueP] = useState('');

    const onClearP = (value) => {
        setFilterValueP('')
        setFilteredProducts([])
    };

    const onSelectP = (value) => {
        setFilterValueP(value);
        // Filter the data based on the new filter value
        const filtered = productList.filter((record) => record.chemical_name === value);
        setFilteredProducts(filtered);
    };
    // const [patentsData, setPatentsData] = useState([]);


    return (
        productList.length?(
            <div className="tableWrapper">
             <div className='rosHeadline'>
                Company Products
            </div>
            <div className="entityTable">
                <div className="companyContacsDiv">
                    <div className="tableTitle">
                        <div className="searchCompanyBox1">
                            <AutoComplete
                                className="autoComplete"
                                options={productList
                                    ? Array.from(new Set(productList.map(entity => JSON.stringify({ value: entity.chemical_name }))), JSON.parse)
                                    : []}
                                onSelect={onSelectP}
                                onClear={onClearP}
                                allowClear={true}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            >
                            <Input
                                className="inputBox"
                                placeholder="Search"
                                prefix={<SearchOutlined />}
                            />
                            </AutoComplete>
                        </div>
                    </div>        
                    <Table 
                        dataSource={filteredProducts.length ? filteredProducts : productList}

                        // pagination={{
                        //     pageSize: 5,
                        //     total: productList?productList.length:0,
                        //     showSizeChanger: false,
                        //     defaultCurrent: 1,
                        //     position: ['bottomCenter'],
                        //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                        //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                        // }}
                        pagination={false}
                        scroll={{
                            y: 400,
                        }}
                        // scroll={{ y: '400px' }}
                    >
                    
                        <Column title="Product" dataIndex="chemical_name" key="chemical_name"  width="40%"
                            render={(chemical_name)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={chemical_name}>
                                        {chemical_name}
                                    </Tooltip>
                                </div>

                            )}
                        />
                         <Column
                            title="Country"
                            dataIndex="country"
                            key="country"
                            render={(text, record) => (
                                <>
                                    <div className="countrySection">
                                        <div
                                            style={{
                                            width: "32px",
                                            height: "32px",
                                            borderRadius: "50%", // Makes it circular
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            overflow: "hidden", // Ensures the flag is not distorted
                                            }}
                                        >
                                            <ReactCountryFlag
                                                countryCode={record.country_code}
                                                svg
                                                style={{
                                                    width: "43px",
                                                    height: "32px"
                                                }}
                                            />
                                        </div>
                                        <div className="countryTable">{ record.country}</div>
                                        
                                    </div>
                                
                                </>
                            )}
                            width="20%"
                        />
                        <Column
                            title="Source"
                            dataIndex="registrations"
                            key="registrations"
                            render={(registrations, record) => (
                                (registrations=='')?
                                    (<></>)
                                :(
                                <>
                                <Tag className="registrationsTable" key={registrations}>
                                    <Tooltip placement="topLeft" title={record.registrations_hover}>
                                        {registrations}
                                    </Tooltip>
                                </Tag>
                                </>)
                            )}
                            width="20%"

                        />
                        {/* <Column title="Capacity" dataIndex="capacity" key="capacity"  width="20%"
                            render={(capacity)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={capacity}>
                                        {capacity}
                                    </Tooltip>
                                </div>

                            )}
                        /> */}
                
                    </Table> 
                </div>  
            </div>
            
            </div>
        ):(<></>)
       
    )
};

export default CompanyProducts;

