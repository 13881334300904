// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
  } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqL_urwDALUqRVEFgaKFIb940GUxZ-plI",
  authDomain: "entvin.firebaseapp.com",
  projectId: "entvin",
  storageBucket: "entvin.appspot.com",
  messagingSenderId: "654313955923",
  appId: "1:654313955923:web:82b3711c99efa4386e9ca6",
  measurementId: "G-T86KTNYZDM"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();


async function sendCreateUserRequest(newUser) {
  try {
    const response = await fetch('https://database.entvin.com/createUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Assuming the API returns the created user in the response
    const createdUser = await response.json();

    console.log('User created via API:', createdUser);
    return createdUser;
  } catch (error) {
    console.error('Error creating user via API:', error.message);
    throw error;
  }
}


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    // user.displayName, user.email, user.uid, 
    await sendCreateUserRequest({
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      type: 'google',
      show_contacts: false,
    });
    console.log(user,'user')
    return user
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
   const res= await signInWithEmailAndPassword(auth, email, password);
   const user = res.user;
   return user
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    console.log(user,name,'user')
    // name, user.email, user.uid, 
    await sendCreateUserRequest({
      uid: user.uid,
      name: name,
      email: user.email,
      type: 'emailandpassword',
      show_contacts: false,
    });

    return user
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};