export default function customFetch(url) {
    const idToken = localStorage.getItem('idToken');
  
    const headers = {
      'Authorization': idToken
      // Add other headers here if needed
    };
    
    return fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': idToken
        }  
    });
}
  