import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VerifyAuth from "./components/VerifyAuth";
import { CompanyPage, Dashboard, Home, SignIn, Pricing } from "./pages";


function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" name="Home" element={<Home />} /> */}
        <Route
          exact
          path="/"
          name="Home"
          element={
            <VerifyAuth>
              <Home />
            </VerifyAuth>
          }
        />
        <Route exact path="/auth" name="SignIn" element={<SignIn />} />

        <Route
          exact
          path="/dashboard"
          name="Dashboard"
          element={
            <VerifyAuth>
              <Dashboard />
            </VerifyAuth>
          }
        />
        <Route
          exact
          path="/company"
          name="Company"
          element={
            <VerifyAuth>
              <CompanyPage />
            </VerifyAuth>
          }
        />
        <Route
          exact
          path="/pricing"
          name="Pricing"
          element={
            <VerifyAuth>
              <Pricing />
            </VerifyAuth>
          }
        />
        
       
        {/* <Route path="*" name="Error page" element={<ErrorPage/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
