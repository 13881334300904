// third party imports
import React, { useState, useEffect } from "react";

// local repo imports
import { Map } from "../../components/index";
import "./EntityLocations.css";
import { countryCords } from '../../utils/countryCord';

const EntityLocations = (props) => {

  const {chemicalInfo} = props
  // const [markers, setMarkers] = useState([])
  
// const markers = [
  //   { lng: -74.006, lat: 40.7128, color: '#6840c6', entityName: 'Entity 1' },
  //   { lng: -74.01, lat: 40.715, color: '#c01573', entityName: 'Entity 2' },
  //   { lng: -74.015, lat: 40.710, color: '#0E1728', entityName: 'Entity 3' },
  // ];
  console.log(chemicalInfo.data,'c1c1c1c1')

    // useEffect(() => {
  //   // This code will run whenever rerender changes
  //   // You can put any logic here that you want to run after a re-render
  //   console.log('rerendered')
  // }, [chemicalInfo]);
  let markers=[]
  if(chemicalInfo.data){
    console.log(chemicalInfo.data)
    // let tempMarkers=[]
    let type_country={}
    chemicalInfo.data.forEach(element=>{
      if(!type_country[element.country.toLowerCase()]){
        type_country[element.country.toLowerCase()]={}
      }
      if(!type_country[element.country.toLowerCase()][element.entity_type]){
        type_country[element.country.toLowerCase()][element.entity_type]=0
      }
      type_country[element.country.toLowerCase()][element.entity_type]+=1
    })
    console.log(type_country)
    Object.keys(type_country).forEach(key=>{
      // console.log(key)
      let country_codes = countryCords[key]
      let country_object = type_country[key]
      console.log(country_object,'sksdjksk')
      Object.keys(country_object).forEach((type, index)=>{
        let color = "#6840c6"
        if(type=='seller'){
          color= '#c01573'
        }
        if(type=='buyer-seller'){
          color= '#0E1728'
        }
        if(type=='buyer'){
          color = "#6840c6"
        }
        let coordiantes=country_codes?country_codes[index]:null
        if(coordiantes){
          markers.push({
            lng: coordiantes.longitude,
            lat: coordiantes.latitude,
            color: color,
            number : country_object[type],
          })
        }
      })
    })
    // chemicalInfo.data.forEach(element => {
    //   let color = "#6840c6"
    //   if(element.entity_type=='seller'){
    //     color= '#c01573'
    //   }
    //   if(element.entity_type=='buyer-seller'){
    //     color= '#0E1728'
    //   }
    //   if(element.entity_type=='buyer'){
    //     color = "#6840c6"
    //   }
      
    //   if(element.cordiantes.lat){
    //     markers.push({
    //       lng: element.cordiantes.lon,
    //       lat: element.cordiantes.lat,
    //       color: color,
    //       entityName: element.company_name,
    //       country: element.country
    //     })
    //   }
    // });  
    console.log(markers)
    // console.log(tempMarkers)
    // setMarkers(tempMarkers)
  }
 
  return (
    <div className="mapSection">
      <div className="mapWrapper">
        <Map 
          markers={markers}
        />
      </div>
      <div className="entitySummary">
        <div className="entityCount">Total {chemicalInfo.data?chemicalInfo.data.length:0} Entities</div>
        <div className="entityTags">
          <div className="div-5">
            <div className="div-4">
              <div className="ellipse" />
              <div className="text">Buyer</div>
            </div>
            <div className="div-4">
              <div className="ellipse-2" />
              <div className="text-2">Seller</div>
            </div>
            <div className="div-4">
              <div className="ellipse-3" />
              <div className="text-3">Buyer/Seller</div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default EntityLocations;
