// third party imports
import React from "react";
import { Navigate } from "react-router-dom";
import { auth} from "../firebase";

import { useAuthState } from "react-firebase-hooks/auth";

// local repo imports

function VerifyAuth({ children }) {
  const [user, loading, error] = useAuthState(auth);
  console.log(user)
  if(!loading){
    if (!user) {
      return <Navigate to="/auth"></Navigate>;
    }else{
      user.getIdToken(true).then(idToken=>{
        localStorage.setItem('idToken',idToken)
      })
    }
  }
 
  return children;
}

export default VerifyAuth;
