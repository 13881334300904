// third part imports
import React, {useState} from "react";
import {  Tag, Card, Tooltip } from 'antd';
import WebsiteLogo from "../../assets/website.png";
import LinkedINLogo from "../../assets/linkedin.png";
import TwitterLogo from "../../assets/twitter.png";
import mapIcon from "../../assets/mapIcon.png";
import ReactCountryFlag from "react-country-flag";

import "./CompanyInfo.css";

const CompanyInfo = (props) => {
    // const { hasCASNumber, chemicalMeta, tags, hiddenTags } = props;
    const {companyInfo} = props
    
    return (

        <div className="companyPageTile">
            <div className="companyPageInfo">
                <div className="companyPageHeader">
                    <Tooltip placement="topLeft" title={companyInfo.company_name}>
                        {companyInfo.company_name}
                    </Tooltip>
                </div>
                <div className="companyCountryandSocials">
                    {
                        companyInfo.address?(
                            <div className="CompanyCountrySection">
                                <div
                                    style={{
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "50%", // Makes it circular
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflow: "hidden", // Ensures the flag is not distorted
                                    }}
                                >
                                    <ReactCountryFlag
                                        countryCode={companyInfo.country_code}
                                        svg
                                        style={{
                                            width: "35px",
                                            height: "24px"
                                        }}
                                    />
                                </div>
                                <div className="countryTable">{ companyInfo.country}</div>

                        
                            </div>
                        ):(<></>)
                    }
                    
                    <div className="socials">
                        {
                            Object.keys(companyInfo.socials).map(type=>{
                                if(companyInfo.socials[type].toString().length>0){
                                    return (
                                        <div className="socialDiv">
                                            <a href={companyInfo.socials[type]} target="_blank">
                                                <img src={type=='website'?WebsiteLogo:(type=='linkedIn'?LinkedINLogo:TwitterLogo)} ></img>
                                            </a>
                                        </div>
                                    )
                                }
                            })
                        }
                        
                    </div>
                </div>
            </div>

            {companyInfo.registrations==''?
                (<></>)
            :(
            <>
                <div className="certificatesRow">
                    {
                        companyInfo.registrations.map(registration => {
                            return (<Tag className="registrationsTable" key={registration}>
                                    <Tooltip placement="topLeft" title={registration}>
                                        {registration}
                                    </Tooltip>
                                </Tag> ) 
                        })
                    }
                              
                </div>
            </>)
            }
            <div className="companyInfoRow">
                { companyInfo.address!=null?(
                    <div style={{ height: "48px", display:"flex", marginBottom:"28px"}}>
                        <div style={{height:"48px"}}>
                            <img src={mapIcon} style={{marginRight: "22px", height:"16px", marginLeft:"10px", marginTop:"16px"}}></img>
                        </div>
                        
                        <div className="companyAddressRow">
                            <div className="manufacturingAddress">
                                Company address                         
                            </div>
                            <div className="addressRow">
                                {companyInfo.address}
                            </div>
                        </div>
                    </div>
                
                    ):(<></>)
                }
            </div>
            <div className="companyInfoCards">
                { Object.keys(companyInfo.company_apollo).map(type=>{

                    let {label, value, image, backgroundColor} = companyInfo.company_apollo[type]
                    
                    return value.length>0?(
                        <Card className="individualInfoCard">
                            <div style={{display: "flex",flexDirection:"row"}}>
                                <div style={{backgroundColor:backgroundColor, width:"48px", height:'48px', borderRadius:'10px', opacity:0.7, alignItems:'center', justifyContent:'center', display:'flex', marginRight:'20px'}} ><img  src={image} alt={label} /> </div>
                                <div>
                                    <div className="individualinfoCardLabel">{label}</div>
                                    <div className="individualinfoCardValue">{value}</div>
                                </div>
                            </div>
                            
                        </Card>
                    ):(<></>)
                    
                })

                }
            </div>
            
        </div>
    )
};
  
export default CompanyInfo;



