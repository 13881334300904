import React from 'react';
import Papa from 'papaparse';
import { Button } from 'antd';

const DownloadButton = ({ data, columns, csvName }) => {
  const handleParsing = async () => {
    // Extracting column titles from the columns array
    const columnTitles = columns.map((column) => column.title);

    // Extracting data based on the dataIndex property of each column
    const csvData = [
      columnTitles.map(title => `"${title}"`).join(','),
      ...data.map(row =>
        columns
          .map(column => {
            const dataIndex = column.dataIndex;
            const value = row[dataIndex];
            // Enclose values containing commas in double quotes
            return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
          })
          .join(',')
      ),
    ].join('\n');

    Papa.parse(csvData, {
      header: true,
      complete: function (result) {
        console.log(result.data);
        // Save the parsed data to a file here if needed
        saveToFile(result.data);
      },
    });
  };

  const saveToFile = async (data) => {
    try {
      // Check if showSaveFilePicker is available (for modern browsers)
      if ('showSaveFilePicker' in window) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: csvName? csvName:'entvin.csv',
          types: [
            {
              description: 'CSV Files',
              accept: {
                'text/csv': ['.csv'],
              },
            },
          ],
        });
        const writable = await fileHandle.createWritable();
        const blob = new Blob([Papa.unparse(data)], { type: 'text/csv' });
        await writable.write(blob);
        await writable.close();
      } else {
        // For Safari and other browsers without showSaveFilePicker
        const blob = new Blob([Papa.unparse(data)], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = csvName? csvName:'entvin.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error saving file:', error);
    }
  };

  return (
    <div>
      <Button type="primary" onClick={handleParsing}>
        Download CSV
      </Button>
    </div>
  );
};

export default DownloadButton;
