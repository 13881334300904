import React from "react";

const getRandomInt = (max, min = 0) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getSearchResult = (query) =>
  new Array(getRandomInt(5))
    .join(".")
    .split(".")
    .map((_, idx) => {
      const category = `${query}${idx}`;
      return {
        value: category,
        label: (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>
              Found {query} on{" "}
              <a
                href={`https://s.taobao.com/search?q=${query}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {category}
              </a>
            </span>
            <span>{getRandomInt(200, 100)} results</span>
          </div>
        ),
      };
    });

export const getModifiedChemicalChildren = (children) => {
  const childrenArray = [...children];
  const splicedChildren = childrenArray.splice(0, 4);
  const hiddenChildren = childrenArray.length;
  const joinedChildren = hiddenChildren ? [childrenArray.join(", ")] : [];
  const modifiedChildren = [...splicedChildren, ...joinedChildren];
  return { showChildren: modifiedChildren, hiddenChildren };
};
