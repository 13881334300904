// third part imports
import React, {useState, useEffect} from "react";
import { Table, Tag, AutoComplete, Input,  Tooltip } from 'antd';
import { SearchOutlined,} from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import customFetch from '../../utils/customFetchFunc';

import {InfoCircleFilled} from '@ant-design/icons';

import './PatentsList.css';

const { Column } = Table;

const PatentsList = (props) => {
    const {chemicalName} = props
    const [patentsData, setPatentsData] = useState([]);

    const getPatentsList = (chemicalName)=>{
        if(chemicalName.length==0){
            return
        }
        customFetch(`https://database.entvin.com/getPatents?chemicalName=${encodeURIComponent(chemicalName.toLowerCase().trim())}`)
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            setPatentsData(data)
        })
        .catch((error) => {
            console.error('Error fetching autocomplete options:', error);
            if ( error.response.status === 401) {
                window.location.reload();
            }
        });
    }

    useEffect(() => {
        getPatentsList(chemicalName)
    }, []);
   


    return (
        patentsData.length?(
            <div className="tableWrapper1">
             <div className='rosHeadline'>
                Patents
            </div>
            <div className='rosBorderBox'>

            </div>
            <div className="entityTable">
                <Table 
                    className="entityListTable"
                    dataSource={patentsData}
                    // pagination={{
                    //     pageSize: 5,
                    //     total: chemicalInfo.data?chemicalInfo.data.length:0,
                    //     showSizeChanger: false,
                    //     defaultCurrent: 1,
                    //     position: ['bottomCenter'],
                    //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                    //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                    // }}
                    pagination={false}
                    scroll={{
                        y: 580,
                    }}
                   
                >
                    
                    <Column title="Product Name" dataIndex="orange_book_database" key="orange_book_database"  width="20%"
                        render={(orange_book_database)=>(
                            <div className="chemicalTable1">
                                <div className="productDescription">
                                    <Tooltip placement="topLeft" title={orange_book_database.ingredient} >
                                        {orange_book_database.ingredient}
                                    </Tooltip>
                                </div>
                            </div>

                        )}
                    />
                     <Column title="Patent Applicant" dataIndex="orange_book_database" key="orange_book_database"  width="20%"
                        render={(orange_book_database)=>(
                            <div className="chemicalTable1">
                                <div className="productDescription">
                                    <Tooltip placement="topLeft" title={orange_book_database.applicant_full_name} >
                                        {orange_book_database.applicant_full_name}
                                    </Tooltip>
                                </div>
                            </div>

                        )}
                    />
                    <Column title="Patent Number" dataIndex="patent_no" key="patent_no"  width="10%"
                        render={(patent_no)=>(
                            <div className="chemicalTable1">
                                <div className="productDescription">
                                    <Tooltip placement="topLeft" title={patent_no} >
                                        <a href={`https://image-ppubs.uspto.gov/dirsearch-public/print/downloadPdf/${patent_no}`} target="_blank">{patent_no}</a> 
                                    </Tooltip>
                                </div>
                            </div>

                        )}
                    />
                    <Column title="Patent Title" dataIndex="patent_title" key="patent_title"  width="40%"
                        render={(patent_title)=>(
                            <div className="chemicalTable1">
                                <div className="productDescription">
                                    <Tooltip placement="topLeft" title={patent_title} >
                                        {patent_title}
                                    </Tooltip>
                                </div>
                            </div>

                        )}
                    />
                    <Column title="Patent Expiry" dataIndex="patent_expire_date_text" key="patent_expire_date_text"  width="10%"
                        render={(patent_expire_date_text)=>(
                            <div className="chemicalTable1">
                                <div className="productDescription">
                                    <Tooltip placement="topLeft" title={patent_expire_date_text} >
                                        {patent_expire_date_text}
                                    </Tooltip>
                                </div>
                            </div>

                        )}
                    />    
                </Table>   
            </div>
            
            </div>
        ):(<></>)
       
    )
};

export default PatentsList;

