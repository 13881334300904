// third part imports
import React, {useState, useEffect} from "react";
import { Table, Tag, AutoComplete, Input,  Tooltip } from 'antd';
import { SearchOutlined,} from "@ant-design/icons";
import ReactCountryFlag from "react-country-flag";
import LinkedinLogo from "../../assets/linkedinLogo.png";
import DownloadButton from "../fileSaver.js";

import {InfoCircleFilled} from '@ant-design/icons';

import './CompanyContacts.css';

const { Column } = Table;

const CompanyContacts = (props) => {
    const {contactList} = props
    const [filteredContact, setFilteredContact] = useState([]);
    const [filterValueC, setFilterValueC] = useState('');

    const onClearC = (value) => {
        setFilterValueC('')
        setFilteredContact([])
    };

    const onSelectC = (value) => {
        setFilterValueC(value);
        // Filter the data based on the new filter value
        const filtered = contactList.filter((record) => (record.name +" - "+ record.title) === value);
        setFilteredContact(filtered);
    };
    // const [patentsData, setPatentsData] = useState([]);


    return (
        contactList.length?(
            <div className="tableWrapper">
             <div className='rosHeadline'>
                Decision Makers
            </div>
            <div className="entityTable">
                <div className="companyContacsDiv">
                    <div className="tableTitle">
                        <div className="searchCompanyBox1">
                            <AutoComplete
                                className="autoComplete"
                                options={contactList
                                    ? Array.from(new Set(contactList.map(entity => JSON.stringify({ value: entity.name + " - " + entity.title }))), JSON.parse)
                                    : []}
                                onSelect={onSelectC}
                                onClear={onClearC}
                                allowClear={true}
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            >
                            <Input
                                className="inputBox"
                                placeholder="Search via Name or Title"
                                prefix={<SearchOutlined />}
                            />
                            </AutoComplete>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '20px' }}>
                        <DownloadButton columns={[
                            {
                                title: 'Name',
                                dataIndex: 'name',
                                key: 'name',
                            },
                            {
                                title: 'Title',
                                dataIndex: 'title',
                                key: 'title',
                            },
                            {
                                title: 'Socials',
                                dataIndex: 'linkedIn',
                                key: 'linkedIn',
                            },{
                                title: 'Company',
                                dataIndex: 'companyName',
                                key: 'companyName',
                            }
                            // Add other columns as needed
                            ]} data={contactList}
                            csvName={'entvin_contacts.csv'}/>

                    </div>        
                    <Table 
                        dataSource={filteredContact.length ? filteredContact : contactList}

                        // pagination={{
                        //     pageSize: 5,
                        //     total: contactList?contactList.length:0,
                        //     showSizeChanger: false,
                        //     defaultCurrent: 1,
                        //     position: ['bottomCenter'],
                        //     prevIcon: <button className="tableButtons"><ArrowLeftOutlined /> Previous</button>, // Custom "Previous" button
                        //     nextIcon: <button className="tableButtons"> Next<ArrowRightOutlined /> </button>, // Custom "Next" button

                        // }}
                        pagination={false}
                        scroll={{
                            y: 400,
                        }}
                        // scroll={{ y: '400px' }}
                    >
                    
                        <Column title="Name" dataIndex="name" key="name"  width="20%"
                            render={(name)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={name}>
                                        {name}
                                    </Tooltip>
                                </div>

                            )}
                        />
                        <Column title="Title" dataIndex="title" key="title"  width="20%"
                            render={(title)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={title}>
                                        {title}
                                    </Tooltip>
                                </div>

                            )}
                        />
                        {/* <Column title="Email" dataIndex="email" key="email"  width="20%"
                            render={(email)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={email}>
                                        {email}
                                    </Tooltip>
                                </div>

                            )}
                        />
                        <Column title="Phone" dataIndex="phone" key="phone"  width="20%"
                            render={(phone)=>(
                                <div className="chemicalTable">
                                    <Tooltip placement="topLeft" title={phone}>
                                        {phone}
                                    </Tooltip>
                                </div>

                            )}
                        /> */}
                        <Column title="Socials" dataIndex="linkedIn" key="linkedIn"  width="20%"
                            render={(linkedIn)=>(
                                <div className="chemicalTable">
                                    <a href={linkedIn} target="_blank" ><img src={LinkedinLogo}/></a>
                                </div>

                            )}
                        />
                    
                        
                
                    </Table> 
                </div>  
            </div>
            
            </div>
        ):(<></>)
       
    )
};

export default CompanyContacts;

