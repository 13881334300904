const countryCords ={
    "niger": [
       {
          "latitude": 13.524834,
          "longitude": 2.109823
       },
       {
          "latitude": 16.972556,
          "longitude": 7.990739
       },
       {
          "latitude": 13.8063421,
          "longitude": 8.9891659
       }
    ],
    "cambodia": [
       {
          "latitude": 11.5730391,
          "longitude": 104.857807
       },
       {
          "latitude": 13.3617562,
          "longitude": 103.8590321
       },
       {
          "latitude": 10.50614075,
          "longitude": 103.2620564
       }
    ],
    "mozambique": [
       {
          "latitude": -25.966213,
          "longitude": 32.56745
       },
       {
          "latitude": -19.828707,
          "longitude": 34.841782
       },
       {
          "latitude": -14.966969,
          "longitude": 39.2707752
       }
    ],
    "kyrgyzstan": [
       {
          "latitude": 42.8777895,
          "longitude": 74.6066926
       },
       {
          "latitude": 40.5303613,
          "longitude": 72.7910097
       },
       {
          "latitude": 41.51073525,
          "longitude": 73.2283479
       }
    ],
    "guadeloupe": [
       {
          "latitude": 16.0000778,
          "longitude": -61.7333373
       },
       {
          "latitude": 16.2408636,
          "longitude": -61.5334077
       },
       {
          "latitude": 16.2706436,
          "longitude": -61.5057749
       }
    ],
    "morocco": [
       {
          "latitude": 33.5945144,
          "longitude": -7.6200284
       },
       {
          "latitude": 31.6258257,
          "longitude": -7.9891608
       },
       {
          "latitude": 34.0346534,
          "longitude": -5.0161926
       }
    ],
    "armenia": [
       {
          "latitude": 40.1777112,
          "longitude": 44.5126233
       },
       {
          "latitude": 40.78031085,
          "longitude": 43.8405281
       },
       {
          "latitude": 40.81366055,
          "longitude": 44.48208513
       }
    ],
    "united states": [
       {
          "latitude": 40.7127281,
          "longitude": -96.921387
       },
       {
          "latitude": 37.0536909,
          "longitude": -96.921387
       },
       {
          "latitude": 34.8755616,
          "longitude": -96.921387
       }
    ],
    "kuwait": [
       {
          "latitude": 29.3796532,
          "longitude": 47.9734174
       },
       {
          "latitude": 28.8673126,
          "longitude": 48.02234154
       },
       {
          "latitude": 29.3232305,
          "longitude": 48.1160653
       }
    ],
    "finland": [
       {
          "latitude": 60.1674881,
          "longitude": 24.9427473
       },
       {
          "latitude": 61.4980214,
          "longitude": 23.7603118
       },
       {
          "latitude": 60.4517531,
          "longitude": 22.2670522
       }
    ],
    "honduras": [
       {
          "latitude": 14.1057433,
          "longitude": -87.2040052
       },
       {
          "latitude": 15.49363865,
          "longitude": -88.00776696
       },
       {
          "latitude": 15.7833743,
          "longitude": -86.7917749
       }
    ],
    "aruba": [
       {
          "latitude": 12.5268736,
          "longitude": -70.0356845
       },
       {
          "latitude": 12.4361328,
          "longitude": -69.9094505
       },
       {
          "latitude": 12.535434,
          "longitude": -70.0049851
       }
    ],
    "colombia": [
       {
          "latitude": 4.6529539,
          "longitude": -74.0835643
       },
       {
          "latitude": 6.2443382,
          "longitude": -75.573553
       },
       {
          "latitude": 3.4520339,
          "longitude": -76.5329352
       }
    ],
    "swaziland": [
       {
          "latitude": -26.325745,
          "longitude": 31.144663
       },
       {
          "latitude": -26.4958706,
          "longitude": 31.3695887
       },
       {
          "latitude": -26.4462848,
          "longitude": 31.2083781
       }
    ],
    "iceland": [
       {
          "latitude": 64.145981,
          "longitude": -21.9422367
       },
       {
          "latitude": 65.68390355,
          "longitude": -18.1121756
       },
       {
          "latitude": 64.0695354,
          "longitude": -21.9575664
       }
    ],
    "venezuela": [
       {
          "latitude": 10.5060934,
          "longitude": -66.9146008
       },
       {
          "latitude": 10.6498095,
          "longitude": -71.6443596
       },
       {
          "latitude": 10.170026,
          "longitude": -68.0003987
       }
    ],
    "martinique": [
       {
          "latitude": 14.6027962,
          "longitude": -61.0676724
       },
       {
          "latitude": 14.614557,
          "longitude": -61.0018145
       },
       {
          "latitude": 14.6776144,
          "longitude": -60.939021
       }
    ],
    "south korea": [
       {
          "latitude": 37.5666791,
          "longitude": 126.9782914
       },
       {
          "latitude": 35.1799528,
          "longitude": 129.0752365
       },
       {
          "latitude": 37.456,
          "longitude": 126.7052
       }
    ],
    "jordan": [
       {
          "latitude": 31.9515694,
          "longitude": 35.9239625
       },
       {
          "latitude": 32.0668425,
          "longitude": 36.0885771
       },
       {
          "latitude": 32.5556091,
          "longitude": 35.8493023
       }
    ],
    "chile": [
       {
          "latitude": -33.4377756,
          "longitude": -70.6504502
       },
       {
          "latitude": -33.0458456,
          "longitude": -71.6196749
       },
       {
          "latitude": -36.8270795,
          "longitude": -73.0502399
       }
    ],
    "malaysia": [
       {
          "latitude": 3.1516964,
          "longitude": 101.6942371
       },
       {
          "latitude": 5.4065013,
          "longitude": 100.2559077
       },
       {
          "latitude": 1.503555,
          "longitude": 103.7495586
       }
    ],
    "ghana": [
       {
          "latitude": 5.5571096,
          "longitude": -0.2012376
       },
       {
          "latitude": 6.6985605,
          "longitude": -1.6233086
       },
       {
          "latitude": 9.4051992,
          "longitude": -0.8423986
       }
    ],
    "senegal": [
       {
          "latitude": 14.693425,
          "longitude": -17.447938
       },
       {
          "latitude": 14.7948093,
          "longitude": -16.9529272
       },
       {
          "latitude": 14.716417,
          "longitude": -17.273844
       }
    ],
    "myanmar": [
       {
          "latitude": 16.7967129,
          "longitude": 96.1609916
       },
       {
          "latitude": 21.9812746,
          "longitude": 96.082375
       },
       {
          "latitude": 19.7540045,
          "longitude": 96.1344976
       }
    ],
    "qatar": [
       {
          "latitude": 25.2856329,
          "longitude": 51.5264162
       },
       {
          "latitude": 24.9304741,
          "longitude": 50.97768878
       },
       {
          "latitude": 25.1566623,
          "longitude": 51.6013212
       }
    ],
    "iraq": [
       {
          "latitude": 33.3061701,
          "longitude": 44.3872213
       },
       {
          "latitude": 36.34369405,
          "longitude": 43.0997156
       },
       {
          "latitude": 30.4952371,
          "longitude": 47.8090981
       }
    ],
    "guinea": [
       {
          "latitude": 9.5170602,
          "longitude": -13.6998434
       },
       {
          "latitude": 7.7589949,
          "longitude": -8.8160001
       },
       {
          "latitude": 10.6248355,
          "longitude": -9.3175166
       }
    ],
    "egypt": [
       {
          "latitude": 30.0443879,
          "longitude": 31.2357257
       },
       {
          "latitude": 31.1991806,
          "longitude": 29.8951716
       },
       {
          "latitude": 29.9870753,
          "longitude": 31.2118063
       }
    ],
    "brunei": [
       {
          "latitude": 4.8895453,
          "longitude": 114.9417574
       },
       {
          "latitude": 4.5845101,
          "longitude": 114.1932373
       },
       {
          "latitude": 4.6141594,
          "longitude": 114.3301832
       }
    ],
    "san marino": [
       {
          "latitude": 43.9458623,
          "longitude": 12.458306
       },
       {
          "latitude": 43.9699026,
          "longitude": 12.4802946
       },
       {
          "latitude": 43.9411865,
          "longitude": 12.447422
       }
    ],
    "puerto rico": [
       {
          "latitude": 18.465299,
          "longitude": -66.116666
       },
       {
          "latitude": 18.3990551,
          "longitude": -66.1560326
       },
       {
          "latitude": 18.3808516,
          "longitude": -65.9570244
       }
    ],
    "jamaica": [
       {
          "latitude": 17.9712148,
          "longitude": -76.7928128
       },
       {
          "latitude": 18.4724603,
          "longitude": -77.9217357
       },
       {
          "latitude": 17.9956926,
          "longitude": -76.9540831
       }
    ],
    "afghanistan": [
       {
          "latitude": 34.5260109,
          "longitude": 69.1776838
       },
       {
          "latitude": 34.3508273,
          "longitude": 62.2164003
       },
       {
          "latitude": 31.6205738,
          "longitude": 65.7157573
       }
    ],
    "norway": [
       {
          "latitude": 59.9133301,
          "longitude": 10.7389701
       },
       {
          "latitude": 60.3943055,
          "longitude": 5.3259192
       },
       {
          "latitude": 63.4304475,
          "longitude": 10.3952118
       }
    ],
    "sierra leone": [
       {
          "latitude": 8.479004,
          "longitude": -13.26795
       },
       {
          "latitude": 7.962065,
          "longitude": -11.73665
       },
       {
          "latitude": 7.8859723,
          "longitude": -11.1863832
       }
    ],
    "congo": [
       {
          "latitude": -4.3217055,
          "longitude": 15.3125974
       },
       {
          "latitude": -11.6642316,
          "longitude": 27.4826264
       },
       {
          "latitude": -6.1258942,
          "longitude": 23.5998111
       }
    ],
    "serbia": [
       {
          "latitude": 44.81533175,
          "longitude": 20.44565877
       },
       {
          "latitude": 45.2551338,
          "longitude": 19.8451756
       },
       {
          "latitude": 43.3211301,
          "longitude": 21.8959232
       }
    ],
    "lao people's democratic republic": [
       {
          "latitude": 17.9640988,
          "longitude": 102.6133707
       },
       {
          "latitude": 19.8887438,
          "longitude": 102.135898
       },
       {
          "latitude": 16.50019585,
          "longitude": 105.7155946
       }
    ],
    "thailand": [
       {
          "latitude": 13.7524938,
          "longitude": 100.4935089
       },
       {
          "latitude": 18.7882778,
          "longitude": 98.9858802
       },
       {
          "latitude": 7.9366015,
          "longitude": 98.3529292
       }
    ],
    "uganda": [
       {
          "latitude": 0.3177137,
          "longitude": 32.5813539
       },
       {
          "latitude": 3.0199023,
          "longitude": 32.38825104
       },
       {
          "latitude": 2.2456918,
          "longitude": 32.896244
       }
    ],
    "guyana": [
       {
          "latitude": 6.8137426,
          "longitude": -58.1624465
       },
       {
          "latitude": 6.0100019,
          "longitude": -58.312488
       },
       {
          "latitude": 6.2426407,
          "longitude": -57.5168359
       }
    ],
    "greece": [
       {
          "latitude": 37.9755648,
          "longitude": 23.7348324
       },
       {
          "latitude": 40.6403167,
          "longitude": 22.9352716
       },
       {
          "latitude": 38.246242,
          "longitude": 21.7350847
       }
    ],
    "poland": [
       {
          "latitude": 52.2319581,
          "longitude": 21.0067249
       },
       {
          "latitude": 50.0619474,
          "longitude": 19.9368564
       },
       {
          "latitude": 51.7687323,
          "longitude": 19.4569911
       }
    ],
    "antigua": [
       {
          "latitude": 17.1184569,
          "longitude": -61.8448509
       },
       {
          "latitude": 17.0659685,
          "longitude": -61.7951413
       },
       {
          "latitude": 17.0387571,
          "longitude": -61.7951103
       }
    ],
    "czech republic": [
       {
          "latitude": 50.0874654,
          "longitude": 14.4212535
       },
       {
          "latitude": 49.1922443,
          "longitude": 16.6113382
       },
       {
          "latitude": 49.8349139,
          "longitude": 18.2820084
       }
    ],
    "benin": [
       {
          "latitude": 6.3676953,
          "longitude": 2.4252507
       },
       {
          "latitude": 6.4990718,
          "longitude": 2.6253361
       },
       {
          "latitude": 9.3400159,
          "longitude": 2.6278258
       }
    ],
    "canada": [
       {
          "latitude": 43.6534817,
          "longitude": -79.3839347
       },
       {
          "latitude": 45.5031824,
          "longitude": -73.5698065
       },
       {
          "latitude": 49.2608724,
          "longitude": -123.113952
       }
    ],
    "bosnia and herzegovina": [
       {
          "latitude": 43.8519774,
          "longitude": 18.3866868
       },
       {
          "latitude": 44.7720845,
          "longitude": 17.1917651
       },
       {
          "latitude": 44.5391105,
          "longitude": 18.675193
       }
    ],
    "chad": [
       {
          "latitude": 12.1191543,
          "longitude": 15.0502758
       },
       {
          "latitude": 8.5654637,
          "longitude": 16.073831
       },
       {
          "latitude": 9.1406471,
          "longitude": 18.3937969
       }
    ],
    "st kitts-nevis-anguilla": [
       {
          "latitude": 17.2962634,
          "longitude": -62.7249244
       },
       {
          "latitude": 17.13789415,
          "longitude": -62.62883872
       }
    ],
    "france": [
       {
          "latitude": 48.8534951,
          "longitude": 2.3483915
       },
       {
          "latitude": 43.2961743,
          "longitude": 5.3699525
       },
       {
          "latitude": 45.7578137,
          "longitude": 4.8320114
       }
    ],
    "brazil": [
       {
          "latitude": -10.3333333,
          "longitude": -53.2
       },
       {
          "latitude": -1.2043218,
          "longitude": -47.1583944
       },
       {
          "latitude": -22.9110137,
          "longitude": -43.2093727
       }
    ],
    "bahrain": [
       {
          "latitude": 26.2235041,
          "longitude": 50.5822436
       },
       {
          "latitude": 26.1315261,
          "longitude": 50.5503431
       },
       {
          "latitude": 26.2465946,
          "longitude": 50.6164127
       }
    ],
    "peru": [
       {
          "latitude": -12.0621065,
          "longitude": -77.0365256
       },
       {
          "latitude": -16,
          "longitude": -72.25
       },
       {
          "latitude": -8.1116778,
          "longitude": -79.0287742
       }
    ],
    "kazakhstan": [
       {
          "latitude": 51.13161485,
          "longitude": 71.43210372
       },
       {
          "latitude": 43.2363924,
          "longitude": 76.9457275
       },
       {
          "latitude": 42.3146962,
          "longitude": 69.5883282
       }
    ],
    "liechtenstein": [
       {
          "latitude": 47.1392862,
          "longitude": 9.5227962
       },
       {
          "latitude": 47.1663397,
          "longitude": 9.510312
       },
       {
          "latitude": 47.106994,
          "longitude": 9.5274876
       }
    ],
    "zimbabwe": [
       {
          "latitude": -17.8567035,
          "longitude": 31.0601584
       },
       {
          "latitude": -20.1053948,
          "longitude": 28.5426856
       },
       {
          "latitude": -18.0143462,
          "longitude": 31.0726737
       }
    ],
    "el salvador": [
       {
          "latitude": 13.6989939,
          "longitude": -89.1914249
       },
       {
          "latitude": 13.9947753,
          "longitude": -89.5566069
       },
       {
          "latitude": 13.4803899,
          "longitude": -88.17722
       }
    ],
    "kenya": [
       {
          "latitude": -1.30261485,
          "longitude": 36.82884202
       },
       {
          "latitude": -4.05052,
          "longitude": 39.667169
       },
       {
          "latitude": -0.1029109,
          "longitude": 34.7541761
       }
    ],
    "papua new guinea": [
       {
          "latitude": -9.4743301,
          "longitude": 147.1599504
       },
       {
          "latitude": -6.7301976,
          "longitude": 147.0008109
       },
       {
          "latitude": -5,
          "longitude": 145.5
       }
    ],
    "switzerland": [
       {
          "latitude": 47.3744489,
          "longitude": 8.5410422
       },
       {
          "latitude": 46.2017559,
          "longitude": 6.1466014
       },
       {
          "latitude": 47.5581077,
          "longitude": 7.5878261
       }
    ],
    "eritrea": [
       {
          "latitude": 15.3389667,
          "longitude": 38.9326763
       },
       {
          "latitude": 15.780477,
          "longitude": 38.453606
       },
       {
          "latitude": 15.6031828,
          "longitude": 39.4636974
       }
    ],
    "dominican republic": [
       {
          "latitude": 18.4801972,
          "longitude": -69.942111
       },
       {
          "latitude": 19.4503932,
          "longitude": -70.6909047
       },
       {
          "latitude": 18.4628148,
          "longitude": -69.9919407
       }
    ],
    "somalia": [
       {
          "latitude": 2.0349312,
          "longitude": 45.3419183
       },
       {
          "latitude": 9.561733,
          "longitude": 44.061803
       },
       {
          "latitude": 10.4386215,
          "longitude": 45.0134007
       }
    ],
    "pakistan": [
       {
          "latitude": 33.6938118,
          "longitude": 73.0651511
       },
       {
          "latitude": 24.8546842,
          "longitude": 67.0207055
       },
       {
          "latitude": 31.5656822,
          "longitude": 74.3141829
       }
    ],
    "bolivia": [
       {
          "latitude": -16.4955455,
          "longitude": -68.1336229
       },
       {
          "latitude": -19.0477251,
          "longitude": -65.2594306
       },
       {
          "latitude": -17.333333,
          "longitude": -61.5
       }
    ],
    "côte d'ivoire": [
       {
          "latitude": 5.320357,
          "longitude": -4.016107
       },
       {
          "latitude": 6.8200066,
          "longitude": -5.2776034
       },
       {
          "latitude": 7.6906058,
          "longitude": -5.0298408
       }
    ],
    "namibia": [
       {
          "latitude": -22.5776104,
          "longitude": 17.0772739
       },
       {
          "latitude": -17.9180402,
          "longitude": 19.75598364
       },
       {
          "latitude": -22.9557607,
          "longitude": 14.5071125
       }
    ],
    "slovakia": [
       {
          "latitude": 48.1516988,
          "longitude": 17.1093063
       },
       {
          "latitude": 48.7172272,
          "longitude": 21.2496774
       },
       {
          "latitude": 49.0000074,
          "longitude": 21.2392122
       }
    ],
    "dominica": [
       {
          "latitude": 15.4240429,
          "longitude": -61.34185329
       },
       {
          "latitude": 15.575915,
          "longitude": -61.455588
       },
       {
          "latitude": 15.5364632,
          "longitude": -61.2793643
       }
    ],
    "haiti": [
       {
          "latitude": 18.547327,
          "longitude": -72.3395928
       },
       {
          "latitude": 18.5345373,
          "longitude": -72.4092495
       },
       {
          "latitude": 18.5518464,
          "longitude": -72.2920071
       }
    ],
    "central african republic": [
       {
          "latitude": 4.3635118,
          "longitude": 18.5835913
       },
       {
          "latitude": 4.3228138,
          "longitude": 18.5248788
       },
       {
          "latitude": 4.2593379,
          "longitude": 15.7877826
       }
    ],
    "denmark": [
       {
          "latitude": 55.6867243,
          "longitude": 12.5700724
       },
       {
          "latitude": 56.1496278,
          "longitude": 10.2134046
       },
       {
          "latitude": 55.3997225,
          "longitude": 10.3852104
       }
    ],
    "ecuador": [
       {
          "latitude": -0.2201641,
          "longitude": -78.5123274
       },
       {
          "latitude": -2.1900563,
          "longitude": -79.8868741
       },
       {
          "latitude": -2.8974072,
          "longitude": -79.0041726
       }
    ],
    "republic of korea": [
       {
          "latitude": 37.5666791,
          "longitude": 126.9782914
       },
       {
          "latitude": 35.1799528,
          "longitude": 129.0752365
       },
       {
          "latitude": 37.456,
          "longitude": 126.7052
       }
    ],
    "united arab emirates": [
       {
          "latitude": 25.2653471,
          "longitude": 55.2924914
       },
       {
          "latitude": 24.4538352,
          "longitude": 54.3774014
       },
       {
          "latitude": 25.2311534,
          "longitude": 54.21900831
       }
    ],
    "moldova": [
       {
          "latitude": 47.0245117,
          "longitude": 28.8322923
       },
       {
          "latitude": 46.8566229,
          "longitude": 29.60591813
       },
       {
          "latitude": 47.7623599,
          "longitude": 27.928665
       }
    ],
    "barbados": [
       {
          "latitude": 13.0977832,
          "longitude": -59.6184184
       },
       {
          "latitude": 13.2500531,
          "longitude": -59.6423782
       },
       {
          "latitude": 13.0631947,
          "longitude": -59.542242
       }
    ],
    "uruguay": [
       {
          "latitude": -34.9058916,
          "longitude": -56.1913095
       },
       {
          "latitude": -31.3571565,
          "longitude": -57.004446
       },
       {
          "latitude": -34.8255833,
          "longitude": -55.958956
       }
    ],
    "australia": [
       {
          "latitude": -33.8698439,
          "longitude": 151.2082848
       },
       {
          "latitude": -37.8142454,
          "longitude": 144.9631732
       },
       {
          "latitude": -27.4689682,
          "longitude": 153.0234991
       }
    ],
    "costa rica": [
       {
          "latitude": 9.9325427,
          "longitude": -84.0795782
       },
       {
          "latitude": 10.45233165,
          "longitude": -84.46632843
       },
       {
          "latitude": 10.3767813,
          "longitude": -84.01397214
       }
    ],
    "cayman islands": [
       {
          "latitude": 19.2953549,
          "longitude": -81.3807776
       },
       {
          "latitude": 19.3720286,
          "longitude": -81.3895724
       },
       {
          "latitude": 19.2843717,
          "longitude": -81.2604411
       }
    ],
    "guatemala": [
       {
          "latitude": 14.6424667,
          "longitude": -90.5131361
       },
       {
          "latitude": 14.6307175,
          "longitude": -90.6061595
       },
       {
          "latitude": 14.5308826,
          "longitude": -90.5963325
       }
    ],
    "sri lanka": [
       {
          "latitude": 6.9388614,
          "longitude": 79.8542005
       },
       {
          "latitude": 6.8369601,
          "longitude": 79.8672866
       },
       {
          "latitude": 6.7746821,
          "longitude": 79.8826095
       }
    ],
    "cyprus": [
       {
          "latitude": 35.1746503,
          "longitude": 33.3638783
       },
       {
          "latitude": 34.6852901,
          "longitude": 33.0332657
       },
       {
          "latitude": 34.9236095,
          "longitude": 33.6236184
       }
    ],
    "oman": [
       {
          "latitude": 23.5882019,
          "longitude": 58.3829448
       },
       {
          "latitude": 23.6821956,
          "longitude": 58.1843842
       },
       {
          "latitude": 17.0174945,
          "longitude": 54.1010923
       }
    ],
    "spain": [
       {
          "latitude": 40.4167047,
          "longitude": -3.7035825
       },
       {
          "latitude": 41.3828939,
          "longitude": 2.1774322
       },
       {
          "latitude": 39.4697065,
          "longitude": -0.3763353
       }
    ],
    "cape verde": [
       {
          "latitude": 14.9162811,
          "longitude": -23.5095095
       },
       {
          "latitude": 16.8874145,
          "longitude": -24.9863842
       },
       {
          "latitude": 16.5986926,
          "longitude": -22.9050314
       }
    ],
    "south africa": [
       {
          "latitude": -26.205,
          "longitude": 28.049722
       },
       {
          "latitude": -33.928992,
          "longitude": 18.417396
       },
       {
          "latitude": -29.861825,
          "longitude": 31.009909
       }
    ],
    "serbia and montenegro": [
       {
          "latitude": 44.819389,
          "longitude": 20.28776511
       }
    ],
    "lebanon": [
       {
          "latitude": 33.8959203,
          "longitude": 35.47843
       },
       {
          "latitude": 34.4373616,
          "longitude": 35.8348551
       },
       {
          "latitude": 33.5618345,
          "longitude": 35.3780338
       }
    ],
    "north korea": [
       {
          "latitude": 39.0167979,
          "longitude": 125.7473609
       },
       {
          "latitude": 39.9165451,
          "longitude": 127.5384272
       },
       {
          "latitude": 41.783248,
          "longitude": 129.8206615
       }
    ],
    "taiwan": [
       {
          "latitude": 25.0375198,
          "longitude": 121.5636796
       },
       {
          "latitude": 22.6203348,
          "longitude": 120.3120375
       },
       {
          "latitude": 24.163162,
          "longitude": 120.6478282
       }
    ],
    "portugal": [
       {
          "latitude": 38.7077507,
          "longitude": -9.1365919
       },
       {
          "latitude": 41.1494512,
          "longitude": -8.6107884
       },
       {
          "latitude": 41.1300103,
          "longitude": -8.6058415
       }
    ],
    "sweden": [
       {
          "latitude": 59.3251172,
          "longitude": 18.0710935
       },
       {
          "latitude": 57.7072326,
          "longitude": 11.9670171
       },
       {
          "latitude": 55.6052931,
          "longitude": 13.0001566
       }
    ],
    "belgium": [
       {
          "latitude": 50.8550018,
          "longitude": 4.351233376
       },
       {
          "latitude": 51.2211097,
          "longitude": 4.3997081
       },
       {
          "latitude": 51.0538286,
          "longitude": 3.7250121
       }
    ],
    "botswana": [
       {
          "latitude": -24.6581357,
          "longitude": 25.9088474
       },
       {
          "latitude": -21.16636,
          "longitude": 27.502515
       },
       {
          "latitude": -24.4058025,
          "longitude": 25.5151645
       }
    ],
    "latvia": [
       {
          "latitude": 56.9493977,
          "longitude": 24.1051846
       },
       {
          "latitude": 55.8712267,
          "longitude": 26.5159337
       },
       {
          "latitude": 56.5048435,
          "longitude": 21.0070903
       }
    ],
    "hungary": [
       {
          "latitude": 47.4978918,
          "longitude": 19.0401609
       },
       {
          "latitude": 47.531399,
          "longitude": 21.6259782
       },
       {
          "latitude": 46.2546312,
          "longitude": 20.1486016
       }
    ],
    "gabon": [
       {
          "latitude": 0.4086518,
          "longitude": 9.4418849
       },
       {
          "latitude": -0.7151966,
          "longitude": 8.7787919
       },
       {
          "latitude": -1.6343979,
          "longitude": 13.5894808
       }
    ],
    "united kingdom": [
       {
          "latitude": 51.5073359,
          "longitude": -0.12765
       },
       {
          "latitude": 52.4796992,
          "longitude": -1.9026911
       },
       {
          "latitude": 53.4794892,
          "longitude": -2.2451148
       }
    ],
    "philippines": [
       {
          "latitude": 14.5906346,
          "longitude": 120.9799964
       },
       {
          "latitude": 14.6508,
          "longitude": 121.04821
       },
       {
          "latitude": 10.2931062,
          "longitude": 123.9020773
       }
    ],
    "suriname": [
       {
          "latitude": 5.82417645,
          "longitude": -55.16628131
       },
       {
          "latitude": 5.6933583,
          "longitude": -55.2141142
       },
       {
          "latitude": 5.91784215,
          "longitude": -56.98483255
       }
    ],
    "germany": [
       {
          "latitude": 52.5170365,
          "longitude": 13.3888599
       },
       {
          "latitude": 53.5672881,
          "longitude": 9.94172615
       },
       {
          "latitude": 48.1371079,
          "longitude": 11.5753822
       }
    ],
    "israel": [
       {
          "latitude": 31.78847185,
          "longitude": 35.21879441
       },
       {
          "latitude": 32.0852997,
          "longitude": 34.7818064
       },
       {
          "latitude": 32.8191218,
          "longitude": 34.9983856
       }
    ],
    "monaco": [
       {
          "latitude": 43.7323492,
          "longitude": 7.4276832
       },
       {
          "latitude": 43.7402961,
          "longitude": 7.426559
       },
       {
          "latitude": 43.7341489,
          "longitude": 7.4217593
       }
    ],
    "maldives": [
       {
          "latitude": 4.1779879,
          "longitude": 73.5107387
       },
       {
          "latitude": -0.6263536,
          "longitude": 73.17910747
       },
       {
          "latitude": -0.20532645,
          "longitude": 73.2569868
       }
    ],
    "bulgaria": [
       {
          "latitude": 42.6977028,
          "longitude": 23.3217359
       },
       {
          "latitude": 42.1418541,
          "longitude": 24.7499297
       },
       {
          "latitude": 43.2073873,
          "longitude": 27.9166653
       }
    ],
    "macao islands": [
       {
          "latitude": 22.20042695,
          "longitude": 113.5673285
       },
       {
          "latitude": 22.14032265,
          "longitude": 113.5646428
       },
       {
          "latitude": 22.14032265,
          "longitude": 113.5646428
       }
    ],
    "gambia": [
       {
          "latitude": 13.4410165,
          "longitude": -16.56275092
       },
       {
          "latitude": 13.4594414,
          "longitude": -16.67461384
       },
       {
          "latitude": 13.25851295,
          "longitude": -16.49970395
       }
    ],
    "grenada": [
       {
          "latitude": 12.0535331,
          "longitude": -61.751805
       },
       {
          "latitude": 12.1228312,
          "longitude": -61.6225151
       },
       {
          "latitude": 12.1665178,
          "longitude": -61.7297144
       }
    ],
    "lithuania": [
       {
          "latitude": 54.6870458,
          "longitude": 25.2829111
       },
       {
          "latitude": 54.8982139,
          "longitude": 23.9044817
       },
       {
          "latitude": 55.7127529,
          "longitude": 21.1350469
       }
    ],
    "saudi arabia": [
       {
          "latitude": 24.638916,
          "longitude": 46.7160104
       },
       {
          "latitude": 21.5810088,
          "longitude": 39.1653612
       },
       {
          "latitude": 21.420847,
          "longitude": 39.826869
       }
    ],
    "azerbaijan": [
       {
          "latitude": 40.16399915,
          "longitude": 50.27770926
       },
       {
          "latitude": 40.6798083,
          "longitude": 46.3597178
       },
       {
          "latitude": 40.703935,
          "longitude": 49.65769298
       }
    ],
    "sudan": [
       {
          "latitude": 15.5635972,
          "longitude": 32.5349123
       },
       {
          "latitude": 15.6445536,
          "longitude": 32.4777311
       },
       {
          "latitude": 15.6478476,
          "longitude": 32.5345797
       }
    ],
    "bhutan": [
       {
          "latitude": 27.4781469,
          "longitude": 89.62627974
       },
       {
          "latitude": 26.8639605,
          "longitude": 89.3837363
       },
       {
          "latitude": 27.4646365,
          "longitude": 89.3183409
       }
    ],
    "bahamas": [
       {
          "latitude": 25.0782266,
          "longitude": -77.3383438
       },
       {
          "latitude": 26.535681,
          "longitude": -78.6953623
       },
       {
          "latitude": 26.5237636,
          "longitude": -78.6597359
       }
    ],
    "mexico": [
       {
          "latitude": 19.4326296,
          "longitude": -99.1331785
       },
       {
          "latitude": 20.6720375,
          "longitude": -103.338396
       },
       {
          "latitude": 25.6802019,
          "longitude": -100.315258
       }
    ],
    "madagascar": [
       {
          "latitude": -18.9100122,
          "longitude": 47.5255809
       },
       {
          "latitude": -18.1553985,
          "longitude": 49.4098352
       },
       {
          "latitude": -19.871403,
          "longitude": 47.034667
       }
    ],
    "ethiopia": [
       {
          "latitude": 9.0358287,
          "longitude": 38.7524127
       },
       {
          "latitude": 9.5913193,
          "longitude": 41.8566373
       },
       {
          "latitude": 13.4842836,
          "longitude": 39.45544933
       }
    ],
    "mongolia": [
       {
          "latitude": 47.94093275,
          "longitude": 106.9179504
       },
       {
          "latitude": 49.0275128,
          "longitude": 104.0447851
       },
       {
          "latitude": 49.4922428,
          "longitude": 105.9361613
       }
    ],
    "luxembourg": [
       {
          "latitude": 49.6112768,
          "longitude": 6.129799
       },
       {
          "latitude": 49.4959628,
          "longitude": 5.9850306
       },
       {
          "latitude": 49.5208469,
          "longitude": 5.889242
       }
    ],
    "marshall islands": [
       {
          "latitude": 7.0909924,
          "longitude": 171.3816354
       },
       {
          "latitude": 8.7841317,
          "longitude": 167.7369226
       },
       {
          "latitude": 9.45272785,
          "longitude": 170.2395135
       }
    ],
    "belarus": [
       {
          "latitude": 53.9024716,
          "longitude": 27.5618225
       },
       {
          "latitude": 53.6834599,
          "longitude": 23.8342648
       },
       {
          "latitude": 53.9090245,
          "longitude": 30.3429838
       }
    ],
    "mauritania": [
       {
          "latitude": 18.0792379,
          "longitude": -15.9780071
       },
       {
          "latitude": 20.9127045,
          "longitude": -17.0503094
       },
       {
          "latitude": 16.6161559,
          "longitude": -11.4002038
       }
    ],
    "libya": [
       {
          "latitude": 32.896672,
          "longitude": 13.1777923
       },
       {
          "latitude": 32.1288331,
          "longitude": 20.0817204
       },
       {
          "latitude": 32.3745923,
          "longitude": 15.0905803
       }
    ],
    "timor-leste": [
       {
          "latitude": -8.5536809,
          "longitude": 125.5784093
       },
       {
          "latitude": -8.4650462,
          "longitude": 126.4585621
       },
       {
          "latitude": -8.721148,
          "longitude": 125.4358593
       }
    ],
    "cuba": [
       {
          "latitude": 23.135305,
          "longitude": -82.3589631
       },
       {
          "latitude": 20.0214637,
          "longitude": -75.8294928
       },
       {
          "latitude": 21.3791765,
          "longitude": -77.9183681
       }
    ],
    "guinea-bissau": [
       {
          "latitude": 11.87018055,
          "longitude": -15.60770456
       },
       {
          "latitude": 12.13485115,
          "longitude": -14.75036099
       },
       {
          "latitude": 12.11703505,
          "longitude": -14.17625058
       }
    ],
    "laos": [
       {
          "latitude": 17.9640988,
          "longitude": 102.6133707
       },
       {
          "latitude": 15.1201461,
          "longitude": 105.8043971
       },
       {
          "latitude": 16.50019585,
          "longitude": 105.7155946
       }
    ],
    "ireland": [
       {
          "latitude": 53.3493795,
          "longitude": -6.2605593
       },
       {
          "latitude": 51.897077,
          "longitude": -8.4654674
       },
       {
          "latitude": 52.661252,
          "longitude": -8.6301239
       }
    ],
    "st. lucia": [
       {
          "latitude": 14.0095966,
          "longitude": -60.9902359
       },
       {
          "latitude": 13.77296925,
          "longitude": -60.95799386
       },
       {
          "latitude": 13.8267684,
          "longitude": -60.94280457
       }
    ],
    "mauritius": [
       {
          "latitude": -20.1624522,
          "longitude": 57.5028044
       },
       {
          "latitude": -20.2252369,
          "longitude": 57.46301134
       },
       {
          "latitude": -20.2933761,
          "longitude": 57.4978036
       }
    ],
    "burkina faso": [
       {
          "latitude": 12.3681873,
          "longitude": -1.5270944
       },
       {
          "latitude": 11.1765796,
          "longitude": -4.2961436
       },
       {
          "latitude": 12.2514911,
          "longitude": -2.3652267
       }
    ],
    "yemen": [
       {
          "latitude": 15.35,
          "longitude": 44.2
       },
       {
          "latitude": 12.833333,
          "longitude": 44.916667
       },
       {
          "latitude": 13.4115414,
          "longitude": 43.5570871
       }
    ],
    "singapore": [
       {
          "latitude": 1.2899175,
          "longitude": 103.8519072
       },
       {
          "latitude": 1.436897,
          "longitude": 103.786216
       },
       {
          "latitude": 1.3546528,
          "longitude": 103.9435712
       }
    ],
    "ukraine": [
       {
          "latitude": 50.4500336,
          "longitude": 30.5241361
       },
       {
          "latitude": 49.9923181,
          "longitude": 36.2310146
       },
       {
          "latitude": 46.4843023,
          "longitude": 30.7322878
       }
    ],
    "palestine": [
       {
          "latitude": 31.9158894,
          "longitude": 35.1781456
       },
       {
          "latitude": 31.5065869,
          "longitude": 34.4615517
       },
       {
          "latitude": 31.5336282,
          "longitude": 35.0979762
       }
    ],
    "hong kong": [
       {
          "latitude": 22.2793278,
          "longitude": 114.1628131
       },
       {
          "latitude": 22.3116452,
          "longitude": 114.1767885
       },
       {
          "latitude": 22.3716605,
          "longitude": 114.1134699
       }
    ],
    "vietnam": [
       {
          "latitude": 21.0358135,
          "longitude": 105.84035
       },
       {
          "latitude": 10.7763897,
          "longitude": 106.7011391
       },
       {
          "latitude": 20.858864,
          "longitude": 106.6749591
       }
    ],
    "tanzania": [
       {
          "latitude": -6.1791181,
          "longitude": 35.7468174
       },
       {
          "latitude": -6.8160837,
          "longitude": 39.2803583
       },
       {
          "latitude": -2.5196915,
          "longitude": 32.9014417
       }
    ],
    "cape verde islands": [
       {
          "latitude": 16.5546085,
          "longitude": -24.08224875
       }
    ],
    "south korea (republic)": [
       {
          "latitude": 37.5358612,
          "longitude": 127.0117095
       }
    ],
    "nigeria": [
       {
          "latitude": 6.4550575,
          "longitude": 3.3941795
       },
       {
          "latitude": 11.8948389,
          "longitude": 8.5364136
       },
       {
          "latitude": 7.3777462,
          "longitude": 3.8972497
       }
    ],
    "uzbekistan": [
       {
          "latitude": 41.3123363,
          "longitude": 69.2787079
       },
       {
          "latitude": 41.0036287,
          "longitude": 71.2611952
       },
       {
          "latitude": 39.6550017,
          "longitude": 66.9756954
       }
    ],
    "antigua and barbuda": [
       {
          "latitude": 17.1184569,
          "longitude": -61.8448509
       },
       {
          "latitude": 17.0659685,
          "longitude": -61.7951413
       },
       {
          "latitude": 17.0387571,
          "longitude": -61.7951103
       }
    ],
    "anguilla": [
       {
          "latitude": 18.2145861,
          "longitude": -63.0517759
       },
       {
          "latitude": 18.2252215,
          "longitude": -63.0438427
       },
       {
          "latitude": 18.2144343,
          "longitude": -63.0288383
       }
    ],
    "fiji": [
       {
          "latitude": -18.1415884,
          "longitude": 178.4421662
       },
       {
          "latitude": -17.6043956,
          "longitude": 177.4530138
       },
       {
          "latitude": -17.7992725,
          "longitude": 177.4178549
       }
    ],
    "angola": [
       {
          "latitude": -8.8272699,
          "longitude": 13.2439512
       },
       {
          "latitude": -12.6076318,
          "longitude": 15.7411039
       },
       {
          "latitude": -12.3506867,
          "longitude": 13.5464318
       }
    ],
    "seychelles": [
       {
          "latitude": -4.6232085,
          "longitude": 55.452359
       },
       {
          "latitude": -4.7110788,
          "longitude": 55.4836699
       },
       {
          "latitude": -4.6135556,
          "longitude": 55.4288241
       }
    ],
    "western sahara": [
       {
          "latitude": 24.1797324,
          "longitude": -13.7667848
       }
    ],
    "rwanda": [
       {
          "latitude": -1.950851,
          "longitude": 30.061507
       },
       {
          "latitude": -2.54261195,
          "longitude": 29.15908705
       },
       {
          "latitude": -2.0845197,
          "longitude": 29.7526948
       }
    ],
    "democratic republic of the congo": [
       {
          "latitude": -4.3217055,
          "longitude": 15.3125974
       },
       {
          "latitude": -11.6642316,
          "longitude": 27.4826264
       },
       {
          "latitude": -6.1258942,
          "longitude": 23.5998111
       }
    ],
    "trinidad and tobago": [
       {
          "latitude": 10.6572678,
          "longitude": -61.5180173
       },
       {
          "latitude": 10.5147394,
          "longitude": -61.4076757
       },
       {
          "latitude": 10.2807069,
          "longitude": -61.4645896
       }
    ],
    "turkey": [
       {
          "latitude": 41.0766019,
          "longitude": 29.052495
       },
       {
          "latitude": 39.9207759,
          "longitude": 32.8540497
       },
       {
          "latitude": 38.23166,
          "longitude": 27.02997
       }
    ],
    "japan": [
       {
          "latitude": 35.6840574,
          "longitude": 139.7744912
       },
       {
          "latitude": 35.4443947,
          "longitude": 139.6367727
       },
       {
          "latitude": 34.6198813,
          "longitude": 135.490357
       }
    ],
    "mali": [
       {
          "latitude": 12.649319,
          "longitude": -8.000337
       },
       {
          "latitude": 11.482629,
          "longitude": -6.860785463
       },
       {
          "latitude": 14.514489,
          "longitude": -3.64645806
       }
    ],
    "panama": [
       {
          "latitude": 8.9714493,
          "longitude": -79.5341802
       },
       {
          "latitude": 9.0551061,
          "longitude": -79.4933063
       },
       {
          "latitude": 9.0920363,
          "longitude": -79.3816691
       }
    ],
    "cameroon": [
       {
          "latitude": 3.8689867,
          "longitude": 11.5213344
       },
       {
          "latitude": 4.0429408,
          "longitude": 9.706203
       },
       {
          "latitude": 5.9614117,
          "longitude": 10.1516505
       }
    ],
    "slovenia": [
       {
          "latitude": 46.0500268,
          "longitude": 14.5069289
       },
       {
          "latitude": 46.5576439,
          "longitude": 15.6455854
       },
       {
          "latitude": 46.2293889,
          "longitude": 15.2616828
       }
    ],
    "equatorial guinea": [
       {
          "latitude": 3.741877,
          "longitude": 8.7740663
       },
       {
          "latitude": 1.82385155,
          "longitude": 9.856166244
       },
       {
          "latitude": 1.96027745,
          "longitude": 11.2524751
       }
    ],
    "northern mariana islands": [
       {
          "latitude": 15.1909825,
          "longitude": 145.746743
       },
       {
          "latitude": 15.2070851,
          "longitude": 145.7208608
       }
    ],
    "liberia": [
       {
          "latitude": 6.328034,
          "longitude": -10.797788
       },
       {
          "latitude": 6.996269,
          "longitude": -9.470905
       },
       {
          "latitude": 6.532326,
          "longitude": -10.3488074
       }
    ],
    "italy": [
       {
          "latitude": 41.8933203,
          "longitude": 12.4829321
       },
       {
          "latitude": 45.4641943,
          "longitude": 9.1896346
       },
       {
          "latitude": 40.8358846,
          "longitude": 14.2487679
       }
    ],
    "new zealand": [
       {
          "latitude": -36.5412811,
          "longitude": 174.5506105
       },
       {
          "latitude": -41.2887953,
          "longitude": 174.7772114
       },
       {
          "latitude": -43.530955,
          "longitude": 172.6364343
       }
    ],
    "malawi": [
       {
          "latitude": -13.9875107,
          "longitude": 33.768144
       },
       {
          "latitude": -15.7862543,
          "longitude": 35.0035694
       },
       {
          "latitude": -11.4607518,
          "longitude": 34.0226422
       }
    ],
    "micronesia": [
       {
          "latitude": 6.920744,
          "longitude": 158.1627143
       },
       {
          "latitude": 7.4425256,
          "longitude": 151.8584946
       },
       {
          "latitude": 5.326022,
          "longitude": 163.0087193
       }
    ],
    "turkmenistan": [
       {
          "latitude": 37.9404648,
          "longitude": 58.3823487
       },
       {
          "latitude": 40.0053169,
          "longitude": 52.9928838
       },
       {
          "latitude": 41.3862117,
          "longitude": 58.5586047
       }
    ],
    "guam": [
       {
          "latitude": 13.47292,
          "longitude": 144.7532528
       },
       {
          "latitude": 13.519054,
          "longitude": 144.8378388
       },
       {
          "latitude": 13.4937451,
          "longitude": 144.7773817
       }
    ],
    "slovak republic": [
       {
          "latitude": 48.1516988,
          "longitude": 17.1093063
       },
       {
          "latitude": 48.7172272,
          "longitude": 21.2496774
       },
       {
          "latitude": 49.0000074,
          "longitude": 21.2392122
       }
    ],
    "argentina": [
       {
          "latitude": -34.6075682,
          "longitude": -58.4370894
       },
       {
          "latitude": -31.4166867,
          "longitude": -64.1834193
       },
       {
          "latitude": -32.9593609,
          "longitude": -60.6617024
       }
    ],
    "russia": [
       {
          "latitude": 55.625578,
          "longitude": 37.6063916
       },
       {
          "latitude": 59.9606739,
          "longitude": 30.1586551
       },
       {
          "latitude": 55.0288307,
          "longitude": 82.9226887
       }
    ],
    "indonesia": [
       {
          "latitude": -6.175247,
          "longitude": 106.8270488
       },
       {
          "latitude": -7.2459717,
          "longitude": 112.7378266
       },
       {
          "latitude": -6.9215529,
          "longitude": 107.6110212
       }
    ],
    "netherlands": [
       {
          "latitude": 52.3730796,
          "longitude": 4.8924534
       },
       {
          "latitude": 51.9244424,
          "longitude": 4.47775
       },
       {
          "latitude": 52.07494555,
          "longitude": 4.269680221
       }
    ],
    "albania": [
       {
          "latitude": 41.3281482,
          "longitude": 19.8184435
       },
       {
          "latitude": 41.313255,
          "longitude": 19.4462348
       },
       {
          "latitude": 40.4707602,
          "longitude": 19.4912716
       }
    ],
    "malta": [
       {
          "latitude": 35.8989818,
          "longitude": 14.5136759
       },
       {
          "latitude": 35.8994547,
          "longitude": 14.4649578
       },
       {
          "latitude": 35.9079178,
          "longitude": 14.4241508
       }
    ],
    "são tomé and príncipe": [
       {
          "latitude": 0.3389242,
          "longitude": 6.7313031
       },
       {
          "latitude": 0.31697115,
          "longitude": 6.540846655
       },
       {
          "latitude": 0.26218925,
          "longitude": 6.707098639
       }
    ],
    "burundi": [
       {
          "latitude": -3.3638125,
          "longitude": 29.3675028
       },
       {
          "latitude": -2.7462188,
          "longitude": 30.3042422
       },
       {
          "latitude": -3.5382336,
          "longitude": 29.910701
       }
    ],
    "montenegro": [
       {
          "latitude": 42.4415238,
          "longitude": 19.2621081
       },
       {
          "latitude": 42.7739388,
          "longitude": 18.9488097
       },
       {
          "latitude": 42.4517622,
          "longitude": 18.5367516
       }
    ],
    "réunion": [
       {
          "latitude": -20.8799889,
          "longitude": 55.448137
       },
       {
          "latitude": -21.0006099,
          "longitude": 55.2771585
       },
       {
          "latitude": -21.341298,
          "longitude": 55.4776174
       }
    ],
    "macedonia": [
       {
          "latitude": 41.9961816,
          "longitude": 21.4319213
       },
       {
          "latitude": 41.0316005,
          "longitude": 21.3302511
       },
       {
          "latitude": 42.1359459,
          "longitude": 21.7177635
       }
    ],
    "paraguay": [
       {
          "latitude": -25.2800459,
          "longitude": -57.6343814
       },
       {
          "latitude": -25.5169015,
          "longitude": -54.6168645
       },
       {
          "latitude": -25.3425224,
          "longitude": -57.509397
       }
    ],
    "romania": [
       {
          "latitude": 44.4361414,
          "longitude": 26.1027202
       },
       {
          "latitude": 46.769379,
          "longitude": 23.5899542
       },
       {
          "latitude": 45.7538355,
          "longitude": 21.2257474
       }
    ],
    "nepal": [
       {
          "latitude": 27.708317,
          "longitude": 85.3205817
       },
       {
          "latitude": 28.209538,
          "longitude": 83.991402
       },
       {
          "latitude": 27.6767339,
          "longitude": 85.316805
       }
    ],
    "croatia": [
       {
          "latitude": 45.8130967,
          "longitude": 15.9772795
       },
       {
          "latitude": 43.5116383,
          "longitude": 16.4399659
       },
       {
          "latitude": 45.3267976,
          "longitude": 14.442208
       }
    ],
    "austria": [
       {
          "latitude": 48.2083537,
          "longitude": 16.3725042
       },
       {
          "latitude": 47.0708678,
          "longitude": 15.4382786
       },
       {
          "latitude": 48.3059078,
          "longitude": 14.286198
       }
    ],
    "syria": [
       {
          "latitude": 33.5130695,
          "longitude": 36.3095814
       },
       {
          "latitude": 36.19924,
          "longitude": 37.1637253
       },
       {
          "latitude": 34.7333334,
          "longitude": 36.7166667
       }
    ],
    "georgia": [
       {
          "latitude": 41.6934591,
          "longitude": 44.8014495
       },
       {
          "latitude": 42.2716078,
          "longitude": 42.7054475
       },
       {
          "latitude": 41.6509502,
          "longitude": 41.6360085
       }
    ],
    "saint lucia": [
       {
          "latitude": 14.0095966,
          "longitude": -60.9902359
       },
       {
          "latitude": 13.77296925,
          "longitude": -60.95799386
       },
       {
          "latitude": 13.90904385,
          "longitude": -60.97792414
       }
    ],
    "china": [
       {
          "latitude": 39.9057136,
          "longitude": 116.3912972
       },
       {
          "latitude": 31.2312707,
          "longitude": 121.4700152
       },
       {
          "latitude": 23.1301964,
          "longitude": 113.2592945
       }
    ],
    "togo": [
       {
          "latitude": 6.130419,
          "longitude": 1.215829
       },
       {
          "latitude": 8.9833194,
          "longitude": 1.1438149
       },
       {
          "latitude": 9.541111,
          "longitude": 0.7876018299
       }
    ],
    "algeria": [
       {
          "latitude": 36.7753606,
          "longitude": 3.0601882
       },
       {
          "latitude": 35.7044415,
          "longitude": -0.6502981
       },
       {
          "latitude": 36.3641642,
          "longitude": 6.6084281
       }
    ],
    "vanuatu": [
       {
          "latitude": -17.7414972,
          "longitude": 168.3150163
       },
       {
          "latitude": -15.5121107,
          "longitude": 167.1784414
       },
       {
          "latitude": -16.057446,
          "longitude": 167.410228
       }
    ],
    "tajikistan": [
       {
          "latitude": 38.5762709,
          "longitude": 68.7863573
       },
       {
          "latitude": 40.2844189,
          "longitude": 69.6191669
       },
       {
          "latitude": 37.90670435,
          "longitude": 69.77616527
       }
    ],
    "zambia": [
       {
          "latitude": -15.357609,
          "longitude": 29.1653092
       },
       {
          "latitude": -12.9693056,
          "longitude": 28.6365894
       },
       {
          "latitude": -12.8104186,
          "longitude": 28.2068361
       }
    ],
    "djibouti": [
       {
          "latitude": 11.8145966,
          "longitude": 42.8453061
       },
       {
          "latitude": 11.2133809,
          "longitude": 42.8699249
       },
       {
          "latitude": 11.7806538,
          "longitude": 42.77901895
       }
    ],
    "nicaragua": [
       {
          "latitude": 12.1544035,
          "longitude": -86.2737642
       },
       {
          "latitude": 12.619767,
          "longitude": -86.5918195
       },
       {
          "latitude": 12.0008289,
          "longitude": -86.0513672
       }
    ],
    "comoros": [
       {
          "latitude": -11.6931255,
          "longitude": 43.2543044
       },
       {
          "latitude": -12.168846,
          "longitude": 44.3942831
       },
       {
          "latitude": -12.284054,
          "longitude": 43.742727
       }
    ],
    "iran": [
       {
          "latitude": 35.6892523,
          "longitude": 51.3896004
       },
       {
          "latitude": 36.2974945,
          "longitude": 59.6059232
       },
       {
          "latitude": 32.6707877,
          "longitude": 51.6650002
       }
    ],
    "estonia": [
       {
          "latitude": 59.4372155,
          "longitude": 24.7453688
       },
       {
          "latitude": 58.3801207,
          "longitude": 26.72245
       },
       {
          "latitude": 59.3766729,
          "longitude": 28.1921457
       }
    ],
    "belize": [
       {
          "latitude": 17.250199,
          "longitude": -88.770018
       },
       {
          "latitude": 17.5000543,
          "longitude": -88.2003115
       },
       {
          "latitude": 17.1582776,
          "longitude": -89.0681672
       }
    ],
    "bangladesh": [
       {
          "latitude": 23.7644025,
          "longitude": 90.389015
       },
       {
          "latitude": 22.333778,
          "longitude": 91.8344348
       },
       {
          "latitude": 22.9372087,
          "longitude": 89.2852741
       }
    ],
    "tunisia": [
       {
          "latitude": 36.8002068,
          "longitude": 10.1857757
       },
       {
          "latitude": 34.7232387,
          "longitude": 10.33584743
       },
       {
          "latitude": 35.8288175,
          "longitude": 10.6405392
       }
    ],
    "russian federation": [
       {
          "latitude": 55.625578,
          "longitude": 37.6063916
       },
       {
          "latitude": 59.9606739,
          "longitude": 30.1586551
       },
       {
          "latitude": 55.0288307,
          "longitude": 82.9226887
       }
    ],
    "india": [
       {
          "latitude": 28.6138954,
          "longitude": 77.2090057
       },
       {
          "latitude": 19.08157715,
          "longitude": 72.88662754
       },
       {
          "latitude": 12.9767936,
          "longitude": 77.590082
       }
    ],
    "macao": [
       {
          "latitude": 22.1899448,
          "longitude": 113.5380454
       },
       {
          "latitude": 22.1573804,
          "longitude": 113.5601035
       },
       {
          "latitude": 22.1239663,
          "longitude": 113.5622645
       }
    ],
    "czechia": [
       {
          "latitude": 50.0874654,
          "longitude": 14.4212535
       },
       {
          "latitude": 49.1922443,
          "longitude": 16.6113382
       },
       {
          "latitude": 49.8349139,
          "longitude": 18.2820084
       }
    ]
 }

 export { countryCords };